import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";


/** Properly aligns buttons inside a form  */
@customElement('odj-form-buttons')
export class OdjFormButtons extends LitElement {

    static override styles = css`:host{display:flex;justify-content:flex-end;width:100%}:host(:not([standalone])){padding-right:var(--odj-status-indicator-width)}.form-buttons{display:flex;gap:1.25rem}`

    /** Enable if the form does not have status indicators. */
    @property({reflect: true, type: Boolean})
    standalone = false

    render() {

        const classes = {
            'form-buttons': true,
            'form-buttons--standalone': this.standalone
        }

        return html`<div class="${classMap(classes)}"><slot></slot></div>`
    }
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-form-buttons": OdjFormButtons;
    }
}