import { css, html, LitElement, PropertyValues } from "lit";
import { customElement, property, query, queryAssignedElements, state } from "lit/decorators.js";
import { OdjTableColumn } from "./table-column";
import { ifDefined } from "lit/directives/if-defined.js";
import { OdjTable } from "./table";
import { FormSubmitController } from "../../utils/form-control";

/**
 * A sortable and filterable table with a local data source.
 *
 * @element odj-smart-table
 * 
 * @slot - Must contain `<odj-table-column>` elements to configure the table columns
 * 
 * @event {{ field: string, direction: string }} odj-table-sort - Fires when the table is sorted.
 * @event {{ field: string, value: string }} odj-table-filter-update - Fires when the user selects a different filter value
 * @event {{ size: number }} odj-table-page-size-change - Fires when the user changes the page size
 * @event {{ page: number }}  odj-table-page-change - Fires when the user navigates to a different page of the table
 * @event {{ value: string | string[] }} odj-change - Fires when the user (de)selects a row. The value is either the identifier of a single-selected row or an array of identifiers with multi-selection. 
 * 
 * @csspart table - The <table>
 * @csspart table-header-row - The header row
 * @csspart table-header-cell - All Cells in the table head
 * @csspart table-header-cell-${column} - One part is exposed for each column header in the table
 * @csspart table-cell - All cells in the table body
 * @csspart table-cell-${column} - One part is exposed for each column in the table
 */
@customElement('odj-smart-table')
export class OdjSmartTable extends LitElement {

    static override styles = css`:host{display:block}`

    @property({type: Array, attribute: 'data', reflect: false})
    data: any[]

    /** Initial page size */
    @property({type: Number, attribute: 'page-size'})
    pageSize = 10

    /** The name of the table in the form. */
    @property()
    name: string

    /** Whether table rows can be selected. If enabled, the table will act as a form element. */
    @property({type: Boolean})
    selectable = false

    /** Whether to allow the selection of multiple rows */
    @property({type: Boolean, attribute: 'multiple'})
    allowMultiselect = false

    /** Column which should be used as identifier for a row. Used to keep track of selections. */
    @property({attribute: 'id-column'})
    idColumn: string

    /** Search value */
    @property({attribute: 'search-value', reflect: false})
    searchValue?: string

    /** Display all data on one page and hide pagination */
    @property({attribute: 'disable-pagination', type: Boolean})
    disablePagination = false

    /** Whether the data for the table is currently being loaded */
    @property({type: Boolean})
    loading = false

    @property({attribute: false})
    rowLinkGenerator: (row: any) => string
    
    /** Caches the initial search value */
    @state()
    initialSearchValue?: string


    @state()
    totalResults = 0

    @state()
    currentPage = 1

    @state()
    sortColumn?: string

    @state()
    sortDirection?: string

    @state()
    filters: {[key: string]: string} = {}

    @state()
    searchable = false
    
    @query('odj-table')
    tableRef: OdjTable

    @queryAssignedElements({ selector: 'odj-table-column', flatten: true })
    columnConfigs: OdjTableColumn[]

    @state()
    shownData: any[]

    @property({attribute: false})
    value: string | string[]

    private formController: FormSubmitController

    constructor() {
        super()
        this.formController = new FormSubmitController(this, {
            value: (control: OdjSmartTable) => control.value,
            disabled: (control: OdjSmartTable) => !control.selectable,
        })
    }


    willUpdate(changed: PropertyValues<this>) {
        if (!this.hasUpdated) {
            this.initialSearchValue = this.searchValue
        }

        if (changed.has('data')) {
            this.resetPagination()

            if (this.hasUpdated) {
                this.updateFilters()
            }

            this.updateDisplayedData()
        }
    }

    firstUpdated(changed: PropertyValues<this>) {
        if (changed.has('data')) {
            this.resetPagination()
            this.updateFilters()

            this.updateDisplayedData()
        }
    }

    private updateFilters() {
        for (let col of this.columnConfigs) {
                
            if (col.filterable && col.filterMode === 'from-data') {
                col.filterValues = Array.from(
                        new Set(
                            this.data
                            .filter(d => d[col.field] !== '')
                            .map(d => (
                                {
                                    value: d[col.field],
                                    label: col.filterLabelField ? d[col.filterLabelField] : d[col.field]
                                })
                            )
                            // Very ugly way to use a set to guarantee uniqueness: transform to JSON first
                            .map(d => JSON.stringify(d))
                        )
                    )
                    // Decode the JSON again after the objects have been passed through the set
                    .map(d => JSON.parse(d))
                    .sort((a, b) => a.label.localeCompare(b.label))
            }
        }
    }

    private handleSort(e: CustomEvent) {
        this.sortDirection = e.detail.direction
        if (this.sortDirection !== undefined) {
            this.sortColumn = e.detail.field
        } else {
            this.sortColumn = undefined
        }
        this.updateDisplayedData()
    }

    private updateDisplayedData() {
        let copy = this.getFilteredData()

        const searchableFields = this.columnConfigs.filter(c => c.searchable).map(c => c.field ?? c.valueFormatter)

        if (this.searchable && this.searchValue) {
            const searchValue = this.searchValue.toLowerCase()
            copy = copy.filter(d => {
                for (let fieldOrValueFormatter of searchableFields) {
                    // If fieldOrValueFormatter is a string, we just get the field value, otherwise we call the value formatter for this column to get the value to be searched
                    const val = typeof fieldOrValueFormatter === 'string' ? d[fieldOrValueFormatter] : typeof fieldOrValueFormatter == 'function' ? fieldOrValueFormatter(d) : ''
                    if ((val || '').toLowerCase().includes(searchValue)) {
                        return true
                    }
                }
                return false
            })
        }

        this.totalResults = copy.length

        if (this.sortColumn !== undefined) {

            const sortCol = Array.from(this.columnConfigs).find(c => c.field === this.sortColumn)
            
            let compareFn = this.getCompareFunction(sortCol);
            let orderFn = this.getOrderFunction();
            
            copy.sort((a, b) => orderFn(compareFn(a, b)))
        }

        this.shownData = !this.disablePagination ? copy.slice((this.currentPage-1)*this.pageSize, this.currentPage*this.pageSize) : copy
    }

    private getFilteredData() {
        return this.data
            .filter(d => {
                for (let kv of Object.entries(this.filters)) {
                    const [key, val] = kv;

                    if (d[key] !== val) {
                        return false;
                    }
                }

                return true;
            });
    }

    private getOrderFunction(): (compResult: number) => number {
        let orderFn: (compResult: number) => number;
        switch (this.sortDirection) {
            case 'asc':
                orderFn = (compResult: number) => compResult;
                break;
            case 'desc':
                orderFn = (compResult: number) => compResult * -1;
                break;
            case undefined:
                orderFn = () => 0;
                break;
        }
        return orderFn;
    }

    private getCompareFunction(sortCol: OdjTableColumn): (a: any, b: any) => number  {
        let compareFn: (a: any, b: any) => number;
        switch (sortCol.type) {
            case 'string':
                compareFn = (a, b) => a[this.sortColumn].localeCompare(b[this.sortColumn]);
                break;
            case 'number':
                compareFn = (a, b) => a[this.sortColumn] - b[this.sortColumn];
                break;
            case 'any':
                if (sortCol.sortFunction === undefined) {
                    throw new Error(`Type of column ${sortCol.field} is 'any', but no sortFunction is not provided.`);
                }
                compareFn = sortCol.sortFunction;
                break;
        }
        return compareFn;
    }

    handlePageSizeChange(e: CustomEvent) {
        this.pageSize = e.detail.size
        this.currentPage = 1
        this.updateDisplayedData()
    }
    
    handlePageChange(e: CustomEvent) {
        this.currentPage = e.detail.page
        this.updateDisplayedData()
    }

    handleFilterUpdate(e: CustomEvent) {
        if (e.detail.value === "") {
            delete this.filters[e.detail.field]
        } else {
            this.filters[e.detail.field] = e.detail.value
        }
        this.currentPage = 1
        this.updateDisplayedData()
    }

    handleSlotChange() {
        // Cache if the table is searchable
        this.searchable = false

        // Read initial filter values from DOM
        for (let c of this.columnConfigs) {
            if (c.filterable && c.initialFilterValue !== undefined) {
                this.filters[c.field] = c.initialFilterValue
            }

            if (c.searchable) {
                this.searchable = true
            }
        }
    }

    handleSelectionChange(e: CustomEvent) {
        this.value = e.detail.value
    }

    reset() {
        this.tableRef?.reset()
        this.value = this.tableRef?.value
    }

    resetPagination() {
        this.currentPage = 1
    }

    handleSearch(e: CustomEvent) {
        e.stopPropagation()
        this.searchValue = e.detail.value.length > 0 ? e.detail.value : undefined
        this.currentPage = 1
        this.updateDisplayedData()
    }

    render() {

        const columnNames = this.columnConfigs.map(c => c.field)

        const parts = ['table-header-row', 'table-header-cell', ...columnNames.map(c => `table-header-cell-${c}`), 'table-cell', ...columnNames.map(c => `table-cell-${c}`)]

        return html`<odj-table .data="${this.shownData}" .rowLinkGenerator="${this.rowLinkGenerator}" name="${ifDefined(this.name)}" id-column="${ifDefined(this.idColumn)}" ?selectable="${this.selectable}" ?multiple="${this.allowMultiselect}" ?hide-pagination="${this.disablePagination}" exportparts="${parts.join(',')}" @odj-table-sort="${this.handleSort}" @odj-table-page-size-change="${this.handlePageSizeChange}" @odj-table-page-change="${this.handlePageChange}" @odj-table-filter-update="${this.handleFilterUpdate}" @odj-table-search-update="${this.handleSearch}" items-per-page="${this.pageSize}" current-page="${this.currentPage}" total-results="${this.totalResults}" .value="${this.value}" search-value="${ifDefined(this.initialSearchValue)}" @odj-change="${this.handleSelectionChange}" ?loading="${this.loading}"><slot name="heading" slot="heading"></slot><slot @slotchange="${this.handleSlotChange}"></slot></odj-table>`
    }

}