import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";


@customElement('odj-demo-table')
export class OdjDemoTable extends LitElement {


    render() {
        return html`<odj-remote-table href="./assets/table.json"><odj-table-column field="productRank" label="Rank" type="number" sortable></odj-table-column><odj-table-column field="subscription" label="Subscription" type="string" sortable filterable filter-mode="from-data"></odj-table-column><odj-table-column field="product" label="Product" type="string" sortable filterable filter-mode="from-data"></odj-table-column><odj-table-column field="action" label="Action" .valueFormatter="${(row: any) => html`<odj-button variant="tertiary" @click="${() => alert('Rank: ' + row.productRank)}"><odj-icon icon="chevron-right-small"></odj-icon></odj-button>`}"></odj-table-column></odj-remote-table>`
    }

}