
type SerializationOptions = {
    /** 
     * A list of keys that should always be converted to an array.
     * 
     * Due to the way the serialization process works, we can't detect whether fields that only
     * emit one value should be converted to a string or an array of strings.
     *  */ 
    forceArrays?: string[]
}


/**
 * Serializes a form and returns a plain object. If a form control with the same name appears more than once, the
 * property will be converted to an array. This behavior can be overriden with the `forceArrays` option.
 * 
 * Inspired by Shoelace: https://github.com/shoelace-style/shoelace/blob/2116ba19f642aa1f21168969d4e778a721c4f76b/src/utilities/form.ts
 */
export function serialize(form: HTMLFormElement, options?: SerializationOptions) {
    const formData = new FormData(form);
    const object: Record<string, unknown> = {};

    const forcedArrays = options?.forceArrays || []

    formData.forEach((value, key) => {
        // We need to decide whether to serialize the data for a given key as string or array.
        // If we encounter the same key twice, we automatically create an array.
        // If the user specified the key in `forceArrays`, we create an array from the start.
        if (Reflect.has(object, key) || forcedArrays.includes(key)) {
            const entry = object[key];
            if (entry === undefined) {
                object[key] = [value]
            } else if (Array.isArray(entry)) {
                entry.push(value);
            } else {
                object[key] = [object[key], value];
            }
        } else {
            object[key] = value;
        }
    });

    return object;
}


