import { html, LitElement } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { componentStyles } from "./global-spinner.styles";


/**
 * 
 * @element odj-global-spinner
 *
 * @slot - additional content (if needed)
 */
@customElement('odj-global-spinner')
export class OdjGlobalSpinner extends LitElement {
    
    static override styles = componentStyles

    /** Whether the global-spinner is visible */
    @property({reflect: true, type: Boolean})
    visible = false

    /** show the global spinner */
    public show() {
        this.visible = true
    }

    /** hide the global spinner */
    public hide() {
        this.visible = false
    }

    render() {
        const classes = {
            'odj-global-spinner': true,
            'odj-global-spinner--shown': this.visible
        }

        return html`<div class="${classMap(classes)}"><div><odj-spinner style="--size:5rem"></odj-spinner><div class="odj-global-spinner__content"><slot></slot></div></div></div>`
    }
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-global-spinner": OdjGlobalSpinner;
    }
}