import {html, LitElement} from 'lit'
import {html as staticHTML, literal as staticLiteral} from 'lit/static-html.js'
import {customElement, property, query, state} from 'lit/decorators.js'
import {classMap} from 'lit/directives/class-map.js'
import {componentStyles} from './button.styles'
import {FormSubmitController} from '../../utils/form-control'
import {ifDefined} from 'lit/directives/if-defined.js';
import {OdjModal} from '../modal'


/**
 * @status stable
 *
 * @dependency odj-icon
 *
 * @slot - The button content
 *
 * @csspart button - The button itself
 * @csspart icon - The icon
 */
@customElement('odj-button')
export class OdjButton extends LitElement {
    static override styles = componentStyles

    /** The style/variant of the button */
    @property({reflect: true})
    variant: 'default' | 'primary' | 'secondary' | 'tertiary' | 'success' | 'danger' | 'icon' | 'link' | '' = 'default'

    /** The link for buttons of type 'link' */
    @property({reflect: true})
    href: string | undefined

    /** The target for buttons of type 'link' */
    @property()
    target: string | undefined

    /** The rel attribute for buttons of type 'link' */
    @property()
    rel: string | undefined

    /** Disables the button */
    @property({reflect: true, attribute: 'disabled', type: Boolean})
    disabled = false

    /** Button type */
    @property()
    type: 'button' | 'submit' | 'link' = 'button';

    /** Button Name */
    @property()
    name: string | undefined;

    /** Button Value */
    @property()
    value: string | undefined;

    /**
     * The "form owner" to associate the button with. If omitted, the closest containing form will be used instead. The
     * value of this attribute must be an id of a form in the same document or shadow root as the button.
     */
    @property() form: string;

    /** Used to override the form owner's `action` attribute. */
    @property({attribute: 'formaction'}) formAction: string;

    /** Used to override the form owner's `method` attribute.  */
    @property({attribute: 'formmethod'}) formMethod: 'post' | 'get';

    /** Used to override the form owner's `novalidate` attribute. */
    @property({attribute: 'formnovalidate', type: Boolean}) formNoValidate: boolean;

    /** Used to override the form owner's `target` attribute. */
    @property({attribute: 'formtarget'}) formTarget: '_self' | '_blank' | '_parent' | '_top' | string;

    /** ID of a modal that should be triggered by this button */
    @property({attribute: 'modal-trigger'})
    modalTrigger?: string

    /** Action to trigger on the referenced modal */
    @property({attribute: 'modal-action'})
    modalAction: 'open' | 'close' = 'open'

    /** Icon to show */
    @property()
    icon: string | undefined

    /** Position of the icon */
    @property({attribute: 'icon-position'})
    iconPosition: 'left' | 'right' = 'left'

    @query('.odj-btn')
    private button: HTMLElement

    @state()
    isIconOnly: boolean

    get isLink() {
        return this.type === 'link'
    }

    private formController: FormSubmitController

    constructor() {
        super()
        this.formController = new FormSubmitController(this, {
            form: (input: HTMLInputElement) => {
                // Buttons support a form attribute that points to an arbitrary form, so if this attribute it set we need to query
                // the form from the same root using its id
                if (input.hasAttribute('form')) {
                    const doc = input.getRootNode() as Document | ShadowRoot;
                    const formId = input.getAttribute('form')!;
                    return doc.getElementById(formId) as HTMLFormElement;
                }

                // Fall back to the closest containing form
                return input.closest('form');
            }
        })

        this.addEventListener('click', this.clickHandler)
    }

    private clickHandler(e: PointerEvent) {
        if (this.disabled) {
            e.preventDefault()
            e.stopImmediatePropagation()
            return
        }

        if (this.type == 'submit' && !e.defaultPrevented) {
            e.preventDefault()
            this.formController.submit(this)
        }

        if (!!this.modalTrigger) {
            const modal = (this.getRootNode() as HTMLElement).querySelector(`#${this.modalTrigger}`) as OdjModal | null

            if (modal) {
                if (modal.tagName.toLowerCase() === 'odj-modal') {
                    switch (this.modalAction) {
                        case 'open':
                            modal.open(this)
                            break
                        case 'close':
                            modal.close(this)
                    }
                } else {
                    console.warn(`Target element with ID ${this.modalTrigger} is not an <odj-modal>.`)
                }
            }
        }

    }

    focus(options?: FocusOptions) {
        this.button.focus(options);
    }

    render() {

        const tag = this.isLink ? staticLiteral`a` : staticLiteral`button`

        const variantClass = `odj-btn--${this.variant}`

        const classes = {
            'odj-btn': true,
            'odj-btn--disabled': this.disabled,
            'odj-btn-icon': this.icon && this.isIconOnly,
            'odj-btn--icon-left': this.icon && !this.isIconOnly && this.iconPosition == 'left',
            'odj-btn--icon-right': this.icon && !this.isIconOnly && this.iconPosition == 'right',
            [variantClass]: true
        }
        return staticHTML`
            <${tag}
                @click="${this.clickHandler}"
                href="${ifDefined(this.href)}" 
                target="${ifDefined(this.target)}" 
                rel="${ifDefined(this.rel)}" 
                type="${ifDefined((['button', 'submit'].includes(this.type)) ? this.type : undefined)}"
                class="${classMap(classes)}"
                part="button"
            >
                ${this.iconPosition == 'left' ? this.getIcon() : ''}
                <slot @slotchange="${this.checkForIconOnly}"></slot>
                ${this.iconPosition == 'right' ? this.getIcon() : ''}
            </${tag}>
        `
    }

    willUpdate() {
        if (!this.hasUpdated) {
            this.checkForIconOnly()
        }
    }

    private checkForIconOnly() {
        this.isIconOnly = this.textContent.trim() == ''
    }

    private getIcon() {
        if (this.icon) {
            return html`<odj-icon icon="${this.icon}" part="icon"></odj-icon>`
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        "odj-button": OdjButton;
    }
}