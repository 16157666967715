import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from 'lit/directives/style-map.js'
import { emitEvent } from "../../internal/event-dispatch";
import { componentStyles } from "./label.styles";


/**
 * Renders a label that is potentially clickable. This component is used for chart legends and the tag input. 
 * 
 * @element odj-label
 * 
 * @event odj-label-action - Fired when the label action is triggered
 * 
 * @slot - The content of the label
 */
@customElement('odj-label')
export class OdjLabel extends LitElement {
    
    static override styles = componentStyles

    /** Renders a rectangle in the provided color in front of the label. This is useful when using this component for chart legends. */
    @property({attribute: 'prefix-color'})
    prefixColor = ''

    /** The size of the label */
    @property()
    size: 'normal' | 'small' = 'normal'
    
    /** The icon to use for the action button */
    @property({attribute: 'action-icon'})
    actionIcon: string

    /** Whether to trigger the action for the whole label or only if clicking the icon */
    @property()
    trigger: 'icon' | 'host' = 'icon'

    private handleActionClick(e: MouseEvent) {
        if (this.trigger === 'icon' && !(e.target as HTMLElement).matches('.action-icon')) {
            return
        }
        emitEvent(this, 'odj-label-action')
    }

    render() {

        /* If the parent element is reachable via keyboard, we don't want the action icon to be focussable as well */
        const tabIndex = this.tabIndex === 0 ? -1 : ''

        return html`<span class="${classMap({'odj-label': true, [`odj-label--${this.size}`]: true})}" part="label" @click="${this.handleActionClick}">${this.prefixColor !== '' ? html`<span class="rectangle" style="${styleMap({background: this.prefixColor})}"></span>` : ''}<slot></slot>${this.actionIcon ? html`<odj-button tabindex="${tabIndex}" icon="${this.actionIcon}" variant="icon" class="action-icon"></odj-button>` : ''}</span>`

    }
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-label": OdjLabel;
    }
}