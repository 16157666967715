import { css, html, LitElement, PropertyValues } from "lit";
import { customElement, property, query, queryAssignedElements, state } from "lit/decorators.js";
import { OdjTableColumn } from "./table-column";
import { ifDefined } from "lit/directives/if-defined.js";
import { FormSubmitController } from "../../utils/form-control";
import { OdjSmartTable } from "./smart-table";

/**
 * A sortable and filterable table with a remote data source.
 *
 * @element odj-remote-table
 * 
 * @slot - Must contain `<odj-table-column>` elements to configure the table columns
 * 
 * @event {{ field: string, direction: string }} odj-table-sort - Fires when the table is sorted.
 * @event {{ field: string, value: string }} odj-table-filter-update - Fires when the user selects a different filter value
 * @event {{ size: number }} odj-table-page-size-change - Fires when the user changes the page size
 * @event {{ page: number }}  odj-table-page-change - Fires when the user navigates to a different page of the table
 * @event {{ value: string | string[] }} odj-change - Fires when the user (de)selects a row. The value is either the identifier of a single-selected row or an array of identifiers with multi-selection. 
 * 
 * @csspart table - The <table>
 * @csspart table-header-row - The header row
 * @csspart table-header-cell - All Cells in the table head
 * @csspart table-header-cell-${column} - One part is exposed for each column header in the table
 * @csspart table-cell - All cells in the table body
 * @csspart table-cell-${column} - One part is exposed for each column in the table
 */
@customElement('odj-remote-table')
export class OdjRemoteTable extends LitElement {
    
    static override styles = css`:host{display:block}`

    /** URL of the data source */
    @property()
    href: string

    /** Initial page size */
    @property({type: Number, attribute: 'page-size'})
    pageSize = 10

    /** The name of the table in the form. */
    @property()
    name: string

    /** Whether table rows can be selected. If enabled, the table will act as a form element. */
    @property({type: Boolean})
    selectable = false

    /** Whether to allow the selection of multiple rows */
    @property({type: Boolean, attribute: 'multiple'})
    allowMultiselect = false

    /** Column which should be used as identifier for a row. Used to keep track of selections. */
    @property({attribute: 'id-column'})
    idColumn: string

    /** Search value */
    @property({attribute: 'search-value', reflect: false})
    searchValue?: string

    @property({attribute: false})
    value: string | string[]

    @property({attribute: false})
    rowLinkGenerator: (row: any) => string

    /** Caches the initial search value */
    @state()
    initialSearchValue?: string

    @state()
    totalResults = 0

    @state()
    currentPage = 1

    @state()
    sortColumn?: string

    @state()
    sortDirection?: string

    @state()
    filters: {[key: string]: string} = {}

    @state()
    isLoading = false

    @state()
    data: any[]
    
    @query('odj-smart-table')
    tableRef: OdjSmartTable

    @queryAssignedElements({ selector: 'odj-table-column', flatten: true })
    columnConfigs: OdjTableColumn[]

    private formController: FormSubmitController

    constructor() {
        super()
        this.formController = new FormSubmitController(this, {
            value: (control: OdjRemoteTable) => control.value,
            disabled: (control: OdjRemoteTable) => !control.selectable,
        })
    }


    async willUpdate(changed: PropertyValues<this>) {
        if (!this.hasUpdated) {
            this.initialSearchValue = this.searchValue
        }
        if (changed.has('href') && this.href) {
            this.isLoading = true
            const resp = await fetch(this.href)

            if (resp.ok) {
                this.data = await resp.json()
            }
            this.isLoading = false
        }
    }

    handleSelectionChange(e: CustomEvent) {
        this.value = e.detail.value
    }

    reset() {
        this.tableRef?.reset()
        this.value = this.tableRef?.value
    }


    render() {
        const columnNames = this.columnConfigs.map(c => c.field)
        const parts = ['table-header-row', 'table-header-cell', ...columnNames.map(c => `table-header-cell-${c}`), 'table-cell', ...columnNames.map(c => `table-cell-${c}`)]
  
        return html`<odj-smart-table .data="${this.data}" .rowLinkGenerator="${this.rowLinkGenerator}" name="${ifDefined(this.name)}" id-column="${ifDefined(this.idColumn)}" ?selectable="${this.selectable}" ?multiple="${this.allowMultiselect}" exportparts="${parts.join(',')}" items-per-page="${this.pageSize}" current-page="${this.currentPage}" total-results="${this.totalResults}" .value="${this.value}" @odj-change="${this.handleSelectionChange}" search-value="${ifDefined(this.initialSearchValue)}" ?loading="${this.isLoading}"><slot name="heading" slot="heading"></slot><slot></slot></odj-smart-table>`
    }

}