import { html, LitElement } from "lit";
import { customElement, property, queryAssignedElements, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { emitEvent } from "../../internal/event-dispatch";
import { FormSubmitController } from "../../utils/form-control";
import { HasSlotController } from "../../internal/slot";
import { watch } from "../../internal/watch";
import { OdjRadioButton } from "../radio-button";
import { OdjRadio } from "../radio";
import { componentStyles } from "./radio-group.styles";
import { OdjRadioTile } from "../radio-tile";

type OdjRadioLike = OdjRadio | OdjRadioButton | OdjRadioTile;
const CHILDREN = ['odj-radio', 'odj-radio-button', 'odj-radio-tile']

/**
 * @element odj-radio-group
 * 
 * @event odj-change - Fires when the radio button value changes
 * 
 * @slot help-text - Allows formatting the help text
 * @slot label - Allows formatting the label
 * 
 * @csspart control-container - The fieldset containing the radio buttons
 * @csspart container - The container for the radio group
 * @csspart label - The label of the radio group
 * @csspart label-tooltip-container - The container of the help tooltip
 * @csspart label-tooltip-content - The help tooltip content
 * @csspart label-tooltip-arrow - The arrow of the help tooltip
 */
@customElement('odj-radio-group')
export class OdjRadioGroup extends LitElement {

    static override styles = componentStyles

    /** Label of the radio group */
    @property()
    label: string | undefined

    /** The help text */
    @property({ attribute: 'help-text' })
    helpText: string

    /** Name of the radio group for form submission */
    @property()
    name: string | undefined

    /** Whether a selection in the radio group is required for form submission */
    @property({ reflect: true, type: Boolean })
    required = false

    /** The current value of the radio group */
    @property({ reflect: false, type: String })
    value: string | undefined

    /** Renders the radio group in a vertical layout */
    @property({ reflect: true, type: Boolean })
    vertical = false

    @property({ reflect: false, type: Boolean})
    disabled = false

    @state()
    invalid = false

    @state()
    private isTouched = false

    @queryAssignedElements()
    private assignedElements: HTMLElement[]

    private formController: FormSubmitController
    private hasSlotController: HasSlotController

    private get assignedRadios(): OdjRadioLike[] {

        return this.assignedElements.map((elem) => {
            if (CHILDREN.includes(elem.tagName.toLowerCase())) {
                return elem as OdjRadioLike
            }

            return [...elem.querySelectorAll(CHILDREN.join(','))] as OdjRadioLike[]
        }).flat()
    }

    constructor() {
        super()
        this.formController = new FormSubmitController(this, {
            disabled: (ctrl: OdjRadioGroup) => ctrl.isDisabled(),
        })
        this.hasSlotController = new HasSlotController(this, 'label', 'help-text')
    }

    firstUpdated() {
        this.updateComplete.then(() => {
            this.value = this.getValue()
        })
    }

    public getValue() {
        const checkedRadios = this.assignedRadios.filter(r => r.checked)

        if (checkedRadios.length == 0) {
            return undefined
        }

        return checkedRadios[0].value
    }

    public isDisabled() {
        if (this.disabled) {
            return true
        }

        const checkedRadios = this.assignedRadios.filter(r => r.checked)

        if (checkedRadios.length == 0) {
            return false
        }

        return checkedRadios[0].disabled
    }


    private handleKeydown(e: KeyboardEvent) {
        
        if (['ArrowDown', 'ArrowRight', 'ArrowUp', 'ArrowLeft'].includes(e.key)) {
            const radios = this.assignedRadios.filter(radio => !radio.disabled && !radio.readonly)
            const checkedRadio = radios.find(radio => radio.checked) ?? radios[0];
            const direction = ['ArrowUp', 'ArrowLeft'].includes(e.key) ? -1 : 1
            let index = radios.indexOf(checkedRadio) + direction
            if (index < 0) {
                index = radios.length - 1
            }
            if (index > radios.length - 1) {
                index = 0
            }

            this.assignedRadios.forEach(radio => {
                radio.checked = false
                radio.input.tabIndex = -1
            })

            radios[index].focus()
            radios[index].checked = true
            radios[index].input.tabIndex = 0

            e.preventDefault()
            this.isTouched = true
        }
    }

    handleRadioClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        const checkedRadio = target.closest(CHILDREN.map(selector => `${selector}:not([disabled]):not([readonly])`).join(','));

        if (checkedRadio) {
            const radios = this.assignedRadios;
            radios.forEach(radio => {
                radio.checked = radio === checkedRadio;
                radio.input.tabIndex = radio === checkedRadio ? 0 : -1
            })
        }
        this.isTouched = true
    }

    @watch('value')
    handleValueChange() {
        const radios = this.assignedRadios
        for (let r of radios) {
            r.checked = r.value === this.value
        }
    }

    private handleSlotChange() {
        this.value = this.getValue()

        const radios = this.assignedRadios
        const checkedRadio = radios.find(radio => radio.checked)

        radios.forEach(radio => {
            radio.setAttribute('role', 'radio')
            if (radio.input) {
                radio.input.tabIndex = -1
            }
        });

        if (checkedRadio) {
            if (checkedRadio.input) {
                checkedRadio.input.tabIndex = 0
            }
        } else if (radios.length > 0) {
            if (radios[0].input) {
                radios[0].input.tabIndex = 0
            }
        }

        this.requestUpdate()
    }

    // Any change will set a radio button and the only validation we do is [required]
    private handleChange(e: CustomEvent) {
        // We only stop the propagation of change events sent by radio buttons
        // since we re-send that event anyway.
        if (!CHILDREN.includes((e.target as HTMLElement).tagName.toLowerCase())) {
            return
        }
        e.stopPropagation()
        this.invalid = false
        this.value = this.getValue()
        emitEvent(this, 'odj-change', {
            detail: {
                value: this.value
            }
        })
    }

    /** Resets the radio group to its initial state and removes any validation state */
    reset() {
        this.assignedRadios.forEach(r => r.checked = r.hasAttribute('checked'))
        this.value = this.getValue()
        this.invalid = false
    }


    reportValidity() {

        if (!this.required) {
            this.invalid = false
            return true
        }
        const radios = this.assignedRadios
        this.invalid = !(radios.filter((r) => r.checked).length > 0)
        return !this.invalid
    }

    render() {

        const hasHelpText = this.hasSlotController.test('help-text') || !!this.helpText
        const hasTiles = this.querySelectorAll('odj-radio-tile').length > 0
        const hasButtons = this.querySelectorAll('odj-radio-button').length > 0

        const classes = {
            'odj-radio-group': true,
            'odj-radio-group--required': this.required,
            'odj-radio-group--touched': this.isTouched,
            'odj-radio-group--invalid': this.invalid,
            'odj-radio-group--valid': !this.invalid,
            'odj-radio-group--vertical': this.vertical,
            'odj-radio-group--horizontal': !this.vertical,
            'odj-radio-group--has-help-text': hasHelpText,
            'odj-radio-group--tiles': hasTiles,
            'odj-radio-group--buttons': hasButtons,
        }

        
        const defaultSlot = html`<slot @slotchange="${this.handleSlotChange}" @click="${this.handleRadioClick}" @keydown="${this.handleKeydown}" @odj-change="${this.handleChange}"></slot>`

        return html`<div class="${classMap(classes)}"><fieldset class="control-container" part="container"><legend class="${classMap({ required: this.required })}" part="label"><slot name="label">${this.label}</slot><odj-tooltip placement="right" exportparts="tooltip-container:label-tooltip-container,tooltip-content:label-tooltip-content,tooltip-arrow:label-tooltip-arrow"><span slot="content"><slot name="help-text">${this.helpText}</slot></span><odj-icon icon="circle-help" class="help-text-icon"></odj-icon></odj-tooltip></legend>${hasButtons ? html`<odj-button-group>${defaultSlot}</odj-button-group>` : defaultSlot}</fieldset></div>`
    }

}

declare global {
    interface HTMLElementTagNameMap {
      "odj-radio-group": OdjRadioGroup;
    }
}