import {LitElement, html, css} from 'lit';
import {customElement, queryAssignedElements} from 'lit/decorators.js';
import {emitEvent} from '../../internal/event-dispatch';

/**
 * @slot selection - Reserved for the selection element; this can be anything that triggers a `odj-change` event corresponding to the requirements of the content-switcher.
 * @slot layer - Reserved for layer elements, each of it should contain a `layer-name` attribute, which must correlate to the `change` event value in order to be shown when selected.
 * 
 * @event {{value: string}} odj-change - Event listened for to make the corresponding layer visible (and hide any other).
 * @event {{layer: string}} odj-layer-change - Triggered when the selected layer is 
 * @fires odj-show-content - Event fired when a layer becomes visible (also initially when a layer is actual visible through the style cascade).
 */
@customElement('odj-content-switcher')
export class OdjContentSwitcher extends LitElement {
	static styles = css`:host{display:block}section{display:block}slot[name=layer]::slotted(*){display:none}slot[name=layer]::slotted([visible]){display:block;margin-top:2rem}`

    @queryAssignedElements({slot: 'layer'})
    private layers: HTMLElement[]

    private shownLayers = new WeakSet<HTMLElement>()

    // Emits the odj-show-content event exactly once for each layer
    private maybeEmitShowContentEvent(elem: HTMLElement) {
        if (this.shownLayers.has(elem)) {
            return
        }
        this.shownLayers.add(elem)
        emitEvent(elem, 'odj-show-content')

    }

    handleLayerChange() {
        for (let ele of this.layers) {
            if (ele.hasAttribute('visible') && window.getComputedStyle(ele).display !== 'none') {

                if (document.readyState === 'interactive' || document.readyState === 'complete') {
                    this.maybeEmitShowContentEvent(ele)
                    continue
                }
    
                document.addEventListener('DOMContentLoaded', () => {
                    this.maybeEmitShowContentEvent(ele);
                }, {once: true})
    
            }
        }
    }

    handleSelectionChange(e: CustomEvent) {
        // Only handle events from direct children
        if (![...this.children].includes(e.target as HTMLElement)) {
            return
        }

        // We only want to emit an `odj-layer-change` event if the layer is actually found
        let found = false
        for (let ele of this.layers) {
            if (ele.getAttribute('layer-name') == e.detail.value) {
                this.maybeEmitShowContentEvent(ele)
                ele.setAttribute('visible', '')
                found = true
            } else {
                ele.removeAttribute('visible');
            }
        }

        if (found) {
            emitEvent(this, 'odj-layer-change', {
                detail: {
                    layer: e.detail.value
                }
            })
        }

    }

	render() {
		return html`<slot name="selection" @odj-change="${this.handleSelectionChange}"></slot><section part="layer-container"><slot name="layer" @slotchange="${this.handleLayerChange}"></slot></section>`
	}
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-content-switcher": OdjContentSwitcher;
    }
}