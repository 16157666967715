import { html, LitElement, PropertyValues, TemplateResult } from "lit"
import { customElement, property, query, state } from "lit/decorators.js"
import { classMap } from "lit/directives/class-map.js"
import { ifDefined } from "lit/directives/if-defined.js"
import { live } from "lit/directives/live.js"
import { emitEvent } from "../../internal/event-dispatch"
import { FormSubmitController } from "../../utils/form-control"
import { renderFormWrapper } from "../../internal/form-wrapper"
import { getElementId } from "../../internal/id-generator"
import { HasSlotController } from "../../internal/slot"
import { getErrorMessage } from "../../utils"
import { componentStyles } from "./textarea.styles"


/**
 * Renders a textarea.
 * 
 * @element odj-textarea
 * 
 * @event {{key: string}} odj-keyup - The keyup event
 * @event {{key: string}} odj-keydown - The keydown event
 * @event odj-input - The input event
 * 
 * @slot label - Allows formatting of the label
 * @slot help-text - Allows formatting of the help text
 */
@customElement('odj-textarea')
export class OdjTextarea extends LitElement {

    static override styles = componentStyles

    /** The name attribute */
    @property()
    name: string | undefined

    /** The label for the textarea */
    @property()
    label: string | undefined

    /** The value of the textarea */
    @property()
    value: string | undefined = ''

    /** The placeholder */
    @property({ reflect: true})
    placeholder: string

    /** The help text */
    @property({ reflect: true, attribute: 'help-text', type: String})
    helpText: string

    /** Whether the textarea is required */
    @property({reflect: true, type: Boolean})
    required: boolean | undefined

    /** Whether the textarea is an invalid validation state */
    @property({ type: Boolean, reflect: true })
    invalid = false

    /** Pre-fills the error message of the form field */
    @property({reflect: false, attribute: 'error-text', type: String})
    errorText: string

    /** The rows attribute */
    @property({ type: Number})
    rows = 4

    /** The minimum length */
    @property({ type: Number})
    minlength: number | undefined

    /** The maximum length */
    @property({ type: Number})
    maxlength: number | undefined

    /** Disables the input. */
    @property({ type: Boolean, reflect: true }) 
    disabled = false

    /** Makes the input readonly. */
    @property({ type: Boolean, reflect: true })
    readonly = false

    /** Renders the textarea at full width without validation status  */
    @property({ type: Boolean, reflect: true, attribute: 'standalone'})
    standalone = false

    @query('textarea')
    input: HTMLTextAreaElement

    @state()
    private isTouched = false

    @state()
    private hasReportedValidity = false

    @state()
    private inputId: string

    @state()
    private labelId: string

    get hasValidation() {
        return !this.standalone && (
            this.required ||
            this.minlength !== undefined ||
            this.maxlength !== undefined ||
            this.hasAttribute('invalid')
        )
    }

    private formController: FormSubmitController
    private hasSlotController: HasSlotController


    constructor() {
        super()
        this.formController = new FormSubmitController(this)
        this.hasSlotController = new HasSlotController(this, 'label', 'help-text')

        this.inputId = 'select-' + getElementId()
        this.labelId = 'label-' + getElementId()
    }

    /** Resets the textarea to its initial state and removes any validation state */
    reset() {
        this.value = this.getAttribute('value') || ''
        this.invalid = false
        this.removeAttribute('invalid')
        this.hasReportedValidity = false
        this.isTouched = false
        this.errorText = ''
    }
    
    
    reportValidity() {
        this.hasReportedValidity = true
        return this.input.reportValidity();
    }

    connectedCallback(): void {
        super.connectedCallback()

        this.updateComplete.then(() => {

            this.input.addEventListener('blur', () => {
                this.isTouched = true
            })
        })
    }

    private updateBuiltInErrors(): boolean {
        let builtInInvalid = false

        // First check build-in validators except customError
        const builtInErrors: (keyof ValidityState)[] = ['badInput', 'patternMismatch', 'rangeOverflow', 'rangeUnderflow', 'tooLong', 'tooShort', 'typeMismatch', 'valueMissing']

        for (const errorType of builtInErrors) {
            if (this.input.validity[errorType]) {

                this.errorText = getErrorMessage(errorType, this.input)
                this.invalid = true
                builtInInvalid = true
                break
            }
        }

        if (!builtInInvalid) {
            this.errorText = ''
            this.invalid = false
        }

        return builtInInvalid
    }

    protected firstUpdated(_changedProperties: PropertyValues<this>): void {
        if (this.errorText !== '') {
            this.input.setCustomValidity(this.errorText || '')
        }
    }

    private handleInvalid(e: Event) {
        e.preventDefault()
        this.updateBuiltInErrors()
        this.invalid = true
    }

    private handleBlur(e: Event) {
        this.updateBuiltInErrors()
    }

    private handleInput(e: Event) {
        this.value = this.input.value
        if (this.isTouched || this.hasReportedValidity) {
            this.input.setCustomValidity('')
            this.updateBuiltInErrors()
        }
        emitEvent(this, 'odj-input')
    }

    private handleKeydown(e: KeyboardEvent) {
        emitEvent(this, 'odj-keydown', {detail: {key: e.key}})
    }


    private handleKeyup(e: KeyboardEvent) {
        emitEvent(this, 'odj-keyup', {detail: {key: e.key}})
    }


    render(): TemplateResult {

        const showValidationResult = this.hasValidation && (this.isTouched || this.hasReportedValidity || this.hasAttribute('invalid'))

        const classes = {
            'odj-textarea': true,
            'odj-textarea--valid': showValidationResult && !this.invalid,
            'odj-textarea--invalid': showValidationResult && this.invalid,
            'odj-textarea--disabled': this.disabled,
            'odj-textarea--readonly': this.readonly,
            'odj-textarea--standalone': this.standalone
        }

        const hasLabel = this.hasSlotController.test('label') || !!this.label
        const hasHelpText = this.hasSlotController.test('help-text') || !!this.helpText

        const control = html`<textarea class="${classMap(classes)}" .value="${live(this.value)}" ?disabled="${this.disabled}" ?readonly="${this.readonly}" ?required="${this.required}" placeholder="${ifDefined(this.placeholder)}" rows="${ifDefined(this.rows)}" minlength="${ifDefined(this.minlength)}" maxlength="${ifDefined(this.maxlength)}" @invalid="${this.handleInvalid}" @blur="${this.handleBlur}" @keydown="${this.handleKeydown}" @keyup="${this.handleKeyup}" @input="${this.handleInput}"></textarea>`

        return renderFormWrapper({
            ref: this,
            helpText: this.helpText,
            id: this.inputId,
            labelId: this.labelId,
            invalid: this.invalid,
            label: this.label,
            hasLabel: hasLabel,
            hasHelpText: hasHelpText,
            required: this.required,
            standalone: this.standalone,
            errorText: this.errorText
        }, control)

    }
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-textarea": OdjTextarea;
    }
}