import { html, LitElement } from "lit"
import { customElement, property, query } from "lit/decorators.js"
import { classMap } from "lit/directives/class-map.js"
import { live } from "lit/directives/live.js";
import { emitEvent } from "../../internal/event-dispatch";
import { FormSubmitController } from "../../utils/form-control";
import { watch } from "../../internal/watch";
import { componentStyles } from "./radio-tile.styles";

/**
 * Provides a radio button in style of an actual button
 * 
 * @element odj-radio-tile
 * 
 * @event odj-change - Fired when the radio button is checked
 * 
 * @slot - the label of the radio button
 */
@customElement('odj-radio-tile')
export class OdjRadioTile extends LitElement {
    
    static override styles = componentStyles

    protected readonly formController = new FormSubmitController(this, {
        value: (control: OdjRadioTile) => (control.checked ? control.value : undefined)
    });

    /** Checks the radio button */
    @property({reflect: false, type: Boolean})
    checked = false

    @property()
    label: string

    /** Disables the radio button */
    @property({reflect: true, type: Boolean})
    disabled = false

    /* This is not currently supported */
    readonly = false

    /** The vale of the radio button */
    @property({reflect: false, type: String})
    value: string

    @query('.odj-btn')
    input: HTMLInputElement


    public focus(options?: FocusOptions): void {
        return this.input.focus(options)
    }

    @watch('checked')
    handleCheckedChange() {
        this.setAttribute('aria-checked', this.checked ? 'true' : 'false')

        if (this.hasUpdated) {
            if (this.checked) {
                emitEvent(this, 'odj-change')
            }
        }
    
    }

    private handleClick() {
        if (!this.disabled) {
            if (!this.checked) {
                this.checked = true
            }
        }
    }

    render() {
        return html`<input type="radio" .checked="${live(this.checked)}" @click="${this.handleClick}" tabindex="-1"> <button type="button" class="${classMap({
                    'odj-btn': true,
                    'odj-btn--checked': this.checked,
                })}"><div class="tile"><div class="label">${this.label}</div><div class="tile-content"><slot></slot></div></div></button>`
    }

}

declare global {
    interface HTMLElementTagNameMap {
      "odj-radio-tile": OdjRadioTile;
    }
}