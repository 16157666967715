import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";


/**
 * Option for <odj-select>. 
 *
 * @element odj-option
 * @slot - Rich HTML content for the option
 */
@customElement('odj-option')
export class OdjSelectOption extends LitElement {

    static override styles = css`:host(:not([is-current-selection]):hover) .item,:host([active]) .item{background:#444}.item{display:flex;align-items:center;padding:var(--odj-option-item-padding,.5rem)}.icon{margin-right:1rem;display:flex;justify-content:center;align-items:baseline}`

    /** Whether the option is currently selected */
    @property({ reflect: false, attribute: 'selected', type: Boolean })
    selected = false

    /** The value of the option */
    @property({ reflect: true })
    value: string | undefined

    @property({reflect: false, attribute: false})
    isPlaceholder = false

    /** An optional icon to display before the label */
    @property({ reflect: true, attribute: 'icon', type: String })
    icon = ''

    /** A state that can only be reached via keyboard navigation. It is equivalent to the hover state. */
    @property({reflect: true, attribute: 'active', type: Boolean})
    active: boolean

    override connectedCallback(): void {
        super.connectedCallback()

        if (!this.hasAttribute('role')) {
            this.setAttribute('role', 'option')
        }
    }

    override render() {
        return html`<div class="item">${this.icon ? html`<div class="icon"><odj-icon icon="${this.icon}"></odj-icon></div>` : html``}<div part="label" class="label"><slot></slot></div></div>`
    }

    getValue() {
        if (this.isPlaceholder) {
            return undefined
        }
        
        if (this.value !== undefined) {
            return this.value
        }

        return this.innerText.trim()
    }

}

declare global {
    interface HTMLElementTagNameMap {
      "odj-option": OdjSelectOption;
    }
}