export function emitEvent(elem: HTMLElement, name: string, options?: CustomEventInit) {
    const event = new CustomEvent(name, {
        composed: true,
        bubbles: true,
        cancelable: false,
        detail: {},
        ...options
    });
    elem.dispatchEvent(event)
    return event
}