import { html, LitElement } from "lit";
import { customElement, property, queryAssignedElements, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { createRef, Ref, ref } from "lit/directives/ref.js";
import { emitEvent } from "../../internal/event-dispatch";
import { HasSlotController } from "../../internal/slot";
import { watch } from "../../internal/watch";
import { componentStyles } from "./collapsible.styles";

/**
 * Renders a collapsible container with a summary.
 * 
 * @slot - The content of the collapsible
 * @slot summary - The summary
 * 
 * @csspart summary - the <summary>
 * @csspart summary-heading - the summary heading wrapper
 * @csspart summary-prefix - the summary prefix
 * @csspart summary-content - the summary content
 * @csspart summary-toggle - the toggle icon of the collapsible
 * @csspart content - the content of the collapsible
 */
@customElement('odj-collapsible')
export class OdjCollapsible extends LitElement {
    
    static override styles = componentStyles

    /** Whether the collapsible is open */
    @property({reflect: true, type: Boolean})
    open = false

    @property({reflect: true, attribute: 'icon-only-toggle', type: Boolean})
    iconOnlyToggle = false

    @state()
    isClosing = false

    private detailsRef: Ref<HTMLDetailsElement> = createRef()
    private summaryRef: Ref<HTMLElement> = createRef()
    private contentRef: Ref<HTMLDivElement> = createRef()

    private animation?: Animation

    private hasSlotController: HasSlotController

    constructor() {
        super()
        this.hasSlotController = new HasSlotController(this, 'prefix')
    }

    handleSummaryToggle(e: Event) {
        if (!this.iconOnlyToggle) {
            this.toggle();
        }
    }

    handleIconToggle(e: Event) {
        this.toggle()
        e.stopPropagation()
    }

    private toggle() {
        const newOpenState = !this.open;

        if (newOpenState) {
            this.open = true;
            this.detailsRef.value.style.height = `${this.detailsRef.value.offsetHeight}px`;
            requestAnimationFrame(() => this.expand());
        } else {
            requestAnimationFrame(() => this.shrink());
        }
    }

    @watch('open')
    handleOpenChange() {
        if (this.open) {
            emitEvent(this, 'odj-show-content')
        }
    }

    // See https://css-tricks.com/how-to-animate-the-details-element-using-waapi/
    private shrink() {
        this.isClosing = true
        this.detailsRef.value.style.overflow = 'hidden'
        // Store the current height of the element
        const startHeight = `${this.detailsRef.value.offsetHeight}px`
        // Calculate the height of the summary
        const endHeight = `${this.summaryRef.value.offsetHeight}px`

        // If there is already an animation running
        this.animation?.cancel()


        // Start a WAAPI animation
        this.animation = this.detailsRef.value.animate({
            // Set the keyframes from the startHeight to endHeight
            height: [startHeight, endHeight]
            }, {
            // If the duration is too slow or fast, you can change it here
            duration: 400,
            // You can also change the ease of the animation
            easing: 'ease'
        })

        this.animation.addEventListener('finish', () => {
            this.handleFinishAnimation(false)
        })

    }

    private expand() {
        this.detailsRef.value.style.overflow = 'hidden'
        // Get the current fixed height of the element
        const startHeight = `${this.detailsRef.value.offsetHeight}px`
        // Calculate the open height of the element (summary height + content height)
        const endHeight = `${this.summaryRef.value.offsetHeight + this.contentRef.value.offsetHeight}px`

        this.animation?.cancel()

        // Start a WAAPI animation
        this.animation = this.detailsRef.value.animate({
            // Set the keyframes from the startHeight to endHeight
            height: [startHeight, endHeight]
        }, {
            // If the duration is too slow of fast, you can change it here
            duration: 400,
            // You can also change the ease of the animation
            easing: 'ease'
        });

        this.animation.addEventListener('finish', () => {
            this.handleFinishAnimation(true)
        })

    }

    private handleFinishAnimation(newOpenState: boolean) {
        this.detailsRef.value.style.overflow = ''
        this.detailsRef.value.style.height = ''
        this.animation = null
        this.open = newOpenState
        this.isClosing = false
    }

    render() {
        const hasPrefix = this.hasSlotController.test('prefix')

        const detailsClasses = {
            details: true,
            'details--open': this.open
        }

        const summaryClasses = {
            summary: true,
            'summary--clickable': !this.iconOnlyToggle
        }

        const summaryHeadingClasses = {
            'summary-heading': true,
            'summary-heading--has-prefix': hasPrefix
        }

        const iconClasses = {
            'icon': true,
            'icon--open': this.open && !this.isClosing,
            'icon--closed': !this.open || this.isClosing
        }

        return html`<div class="${classMap(detailsClasses)}" ${ref(this.detailsRef)}><div class="${classMap(summaryClasses)}" ${ref(this.summaryRef)} @click="${this.handleSummaryToggle}" part="summary"><div class="${classMap(summaryHeadingClasses)}" part="summary-heading"><div class="summary-prefix" part="summary-prefix"><slot name="prefix"></slot></div><div class="summary-content" part="summary-content"><slot name="summary"></slot></div></div><odj-icon class="${classMap(iconClasses)}" icon="chevron-up" part="summary-toggle" @click="${this.handleIconToggle}"></odj-icon></div><div class="content-wrapper" ${ref(this.contentRef)}><div class="content-container" part="content"><slot></slot></div></div></div>`

    }
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-collapsible": OdjCollapsible;
    }
}