import { html, LitElement } from "lit"
import { customElement, property, query, state } from "lit/decorators.js"
import { classMap } from "lit/directives/class-map.js"
import { ifDefined } from "lit/directives/if-defined.js"
import { live } from "lit/directives/live.js"
import { emitEvent } from "../../internal/event-dispatch"
import { getElementId } from "../../internal/id-generator"
import { HasSlotController } from "../../internal/slot"
import { watch } from "../../internal/watch"
import { OdjTooltip } from "../tooltip"
import { componentStyles } from "./radio.styles"


/**
 * 
 * @element odj-radio
 * 
 * @event odj-change - Fired when the radio button is checked
 * 
 * @slot help-text - Allows formatting the help text
 * @slot label - Allows formatting the label
 */
@customElement('odj-radio')
export class OdjRadio extends LitElement {

    static override styles = componentStyles

    /** Name of the radio button for form submission. Usually, it's better to just set the name on the parent `odj-radio-group`. */
    @property({ reflect: true })
    name: string | undefined

    /** Value of the radio button */
    @property({ reflect: true })
    value: string | undefined

    /** Label of the radio button */
    @property()
    label: string

    /** The help text */
    @property({attribute: 'help-text'})
    helpText: string

    /** Checks the radio button */
    @property({ reflect: false, type: Boolean })
    checked = false

    /** Disables the checkbox. */
    @property({ type: Boolean, reflect: true })
    disabled = false

    /** Puts the radio button in readonly mode */
    @property({ type: Boolean, reflect: true })
    readonly = false

    @query('input')
    input: HTMLInputElement

    @query('odj-tooltip')
    private tooltip: OdjTooltip

    @state()
    private hasFocus = false

    @state()
    private isTouched = false


    @state()
    private inputId: string

    @state()
    private labelId: string

    private hasSlotController: HasSlotController

    constructor() {
        super()
        this.hasSlotController = new HasSlotController(this, 'help-text', 'label')
        this.inputId = 'radio-' + getElementId()
        this.labelId = 'label-' + getElementId()

    }

    reportValidity(): boolean {
        return this.input.reportValidity()
    }

    /** Set a custom validation error message */
    setCustomValidity(error: string) {
        return this.input.setCustomValidity(error)
    }

    private handleInput(e: PointerEvent) {
        if (!this.disabled && !this.readonly) {
            if (!this.checked) {
                this.checked = true
            }
        }
    }

    focus(options?: FocusOptions): void {
        this.input.focus(options)
    }

    handleFocus() {
        this.hasFocus = true
        // If the element has keyboard focus, show the help tooltip
        if (this.input.matches(':focus-visible')) {
            this.tooltip?.show()
        }
    }
        

    handleBlur() {
        this.hasFocus = false
        this.isTouched = true
        this.tooltip?.hide()
    }

    getAllRadios() {
        const radioGroup = this.closest('odj-radio-group')

        // Radios must be part of a radio group
        if (radioGroup === null) {
            return [this]
        }

        return [...radioGroup.querySelectorAll('odj-radio')].filter((radio: this) => radio.name === this.name) as this[]
    }

    getSiblingRadios() {
        return this.getAllRadios().filter(radio => radio !== this)
    }


    @watch('checked')
    handleCheckedChange() {
        this.setAttribute('aria-checked', this.checked ? 'true' : 'false')

        if (this.hasUpdated) {
            if (this.checked) {
                emitEvent(this, 'odj-change')
            }
        }
    
    }


    render() {

        const classes = {
            'odj-radio': true,
            'odj-radio--disabled': this.disabled,
            'odj-radio--readonly': this.readonly,
        }

        const hasHelpText = this.hasSlotController.test('help-text') || !!this.helpText

        const formFieldClasses = {
            'form-field': true,
            'form-field--disabled': this.readonly || this.disabled,
            'form-field--has-help-text': hasHelpText,
            'form-field--checked': this.checked,
        }

        return html`<div class="${classMap(formFieldClasses)}"><label .id="${this.labelId}" class="${classMap(classes)}"><input type="radio" class="control" name="${ifDefined(this.name)}" .id="${this.inputId}" .value="${live(this.value)}" .checked="${live(this.checked)}" ?disabled="${this.disabled}" ?readonly="${this.readonly}" aria-labelledby="${this.labelId}" aria-checked="${this.checked ? 'true' : 'false'}" @blur="${this.handleBlur}" @focus="${this.handleFocus}" @input="${this.handleInput}"><slot name="label">${this.label}</slot><odj-tooltip placement="right"><span slot="content"><slot name="help-text">${this.helpText}</slot></span><odj-icon icon="circle-help" class="help-text-icon"></odj-icon></odj-tooltip><span class="form-field-status" part="status-indicator"><odj-icon icon="check-small" part="status-indicator-icon"></odj-icon></span></label></div>`

    }
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-radio": OdjRadio;
    }
}