import { css, html, LitElement } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { createToast, OdjToastContainer } from "../toast";


/**
 * Provides a container to easily copy its contents to the clipboard.
 * 
 * @element odj-copy-container
 * 
 */
@customElement('odj-copy-container')
export class OdjCopyContainer extends LitElement {
    
    static override styles = css`:host([block]){display:block;position:relative;margin:1px}:host([block]) .content{padding:.5rem}.content{--color:#5A6268;cursor:pointer;padding:.25rem;display:inherit}.content:hover{--color:#fff;border-radius:4px;background:rgba(var(--odj-white-rgb),.1)}odj-icon{vertical-align:text-bottom;font-size:1.5rem;color:var(--color)}:host([block]) odj-icon{position:absolute;bottom:.5rem;right:.5rem}`

    /** Use this attribute when wrapping a block element in order to position the clipboard icon correctly and make the outline work */
    @property({type: Boolean})
    block = false

    @query('odj-toast-container')
    toastContainer: OdjToastContainer

    private async handleCopy() {
        await navigator.clipboard.writeText(this.innerText)

        createToast(this.toastContainer, 'Copied to clipboard.', 'success', 3000)
    }

    render() {
        return html`<div class="content" @click="${this.handleCopy}"><slot></slot><odj-tooltip content="Copy to cliboard" placement="auto"><odj-icon icon="clipboard" role="button"></odj-icon></odj-tooltip></div><odj-toast-container></odj-toast-container>`
    }

}

declare global {
    interface HTMLElementTagNameMap {
      "odj-copy-container": OdjCopyContainer;
    }
}