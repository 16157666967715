import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { HasSlotController } from "../../internal/slot";
import { componentStyles } from "./alert.styles";


/** 
 * Renders an alert 
 * 
 * @slot - The content of the alert
 * @slot heading - The heading of the alert
*/
@customElement('odj-alert')
export class OdjAlert extends LitElement {

    static override styles = componentStyles

    @property()
    type: 'success' | 'warning' | 'info' | 'danger' = 'info'

    hasSlotController: HasSlotController

    constructor() {
        super()
        this.hasSlotController = new HasSlotController(this, 'heading')
    } 

    render() {

        const hasHeading = this.hasSlotController.test('heading')

        const classes = {
            'alert': true,
            [`alert--${this.type}`]: true,
            'alert--has-heading': hasHeading
        }


        return html`<section class="${classMap(classes)}"><header class="alert-heading"><slot name="heading"></slot></header><slot></slot></section>`
    }
    
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-alert": OdjAlert;
    }
}