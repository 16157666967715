import { LitElement, PropertyValueMap, html } from "lit";
import { customElement, eventOptions, property, query, queryAll, queryAssignedNodes, state } from "lit/decorators.js";
import { OdjInput } from "../input";

import {componentStyles} from './autocomplete.styles'
import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js"
import { emitEvent } from "../../internal/event-dispatch";
import { FormSubmitController} from "../../utils";
import { HasSlotController } from "../../internal/slot";



type Result = {
    value: string
    title: string
    subtitle: string
}

/**
 * @element odj-autocomplete
 * 
 * @event odj-change - Fires whenever the user selects a new value
 * 
 * @slot help-text - The help text
 * @slot no-results-text - The text that is displayed if there are no results
 */
@customElement('odj-autocomplete')
export class OdjAutocomplete extends LitElement {

    static override styles = [componentStyles]

    /** Name of the field in form submisions */
    @property()
    name: string

    /** Label of the input field */
    @property()
    label: string

    /** URL to request the suggestions from. Use {query} a placeholder in the URL. */
    @property()
    url: string

    /** Value that is submitted as part of forms  */
    @property({reflect: false})
    value = ''

    /** Value that is displayed in the input field. Can be something human-readable. */
    @property({reflect: false, attribute: 'display-value'})
    displayValue = ''

    /** Minimum length for autocompletion to start */
    @property({attribute: 'min-search-length', type: Number})
    minSearchLength = 2

    /** Whether the input is disabled */
    @property({type: Boolean})
    disabled = false

    /** Whether the input is readonly (disabled, but still submitted) */
    @property({type: Boolean})
    readonly = false

    @property({type: Boolean})
    required = false

    @property({attribute: 'help-text'})
    helpText: string

    @property({attribute: 'no-results-text'})
    noResultsText: string

    @query('odj-input')
    input: OdjInput


    @query('.results')
    resultList: HTMLElement

    @queryAll('li')
    resultElems: HTMLUListElement[]

    @state()
    inEditMode = false

    @state()
    isLoading = false

    @state()
    hasLoadedResults = false

    @state()
    results: Result[] = []

    @state()
    showResults = false

    @state()
    selectedIdx = -1

    @state()
    inputValue = ''

    @state()
    selectedDisplayValue = ''

    @state()
    selectedValue = ''

    @state()
    isTouched = false

    @state()
    invalid = false

    // Is used to cancel the currently running request if there is new input
    abortController: AbortController

    debounce: ReturnType<typeof setTimeout> 

    private formController: FormSubmitController
    private hasSlotController: HasSlotController
    private mutationObserver: MutationObserver

    constructor() {
        super()

        this.formController = new FormSubmitController(this)
        this.hasSlotController = new HasSlotController(this, 'help-text')

        this.handleOutsideClick = this.handleOutsideClick.bind(this)

        // We only inject the slot for the help text if we actually have it
        // We need to watch for mutations and trigger an update to make
        // sure that we have the correct state for hasHelpText at all
        // times.
        this.mutationObserver = new MutationObserver((mutations) => {
            this.requestUpdate()
        })

    }

    override connectedCallback(): void {
        super.connectedCallback()
        document.addEventListener('click', this.handleOutsideClick)

        this.mutationObserver.observe(this, {
            childList: true,
        })

    }

    override disconnectedCallback(): void {
        super.disconnectedCallback()
        document.removeEventListener('click', this.handleOutsideClick)
    }

    protected willUpdate(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        if (_changedProperties.has('displayValue')) {
            this.inputValue = this.displayValue
        }
    }

    protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        if (!this.value) {
            this.inEditMode = true
        }
    }

    async handleInput(e: CustomEvent) {
        this.inputValue = this.input.value

        this.abortResults();

        this.debounce = setTimeout(async () => {
            if (this.input.value.length < this.minSearchLength) {
                return
            }

            this.isLoading = true

            if (!this.showResults) {
                this.openResults()
            }
    
            const abortController = new AbortController()
            this.abortController = abortController
            const signal = abortController.signal
    
            try {
                const resp = await fetch(this.url.replace('{query}', this.input.value), { signal })
                
                if (signal && signal.aborted) {
                    throw new DOMException('Request cancelled', 'AbortError')
                }
    
                if (resp.ok) {
                    const json = await resp.json()
        
                    this.results = json.items || []
                    this.selectedIdx = -1
                    this.hasLoadedResults = true
                } else {
                    this.results = []
                    this.showResults = false
                }
            } catch(e) {
                return
            } finally {
                this.isLoading = false
            }
    
        }, 250)
    }

    private abortResults() {
        if (this.debounce) {
            clearTimeout(this.debounce);
        }

        if (this.abortController) {
            this.abortController.abort();
        }
    }

    openResults() {
        this.showResults = true
    }

    handleFocus() {
        this.isTouched = true
        if (this.results.length > 0) {
            this.showResults = true
        }
    }

    closeResults() {
        this.abortResults()
        this.showResults = false
        this.selectedIdx = -1
    }

    @eventOptions({capture: true})
    handleKeydown(e: KeyboardEvent) {
        if (e.key === 'ArrowDown') {
            e.preventDefault()
            if (!this.showResults) {
                this.openResults()
                this.selectedIdx = 0
                return
            }

            if (this.selectedIdx + 1 < this.results.length) {
                this.selectedIdx++
                this.scrollSelectedItemIntoView()
            }
        } else if (e.key === 'ArrowUp') {
            if (this.selectedIdx > 0) {
                this.selectedIdx--
                this.scrollSelectedItemIntoView()
            }
            e.preventDefault()
        } else if (e.key === 'Escape' || e.key === 'Esc') {
            this.closeResults()
        } else if (e.key === 'Enter') {
            if (this.selectedIdx >= 0) {
                this.selectValue(this.results[this.selectedIdx].title, this.results[this.selectedIdx].value)
                this.closeResults()
            } else {
                this.closeEditMode(true)
            }
        } else if (e.key === 'Tab') {
            this.closeResults()
        }
    }

    private scrollSelectedItemIntoView() {
        setTimeout(() => {
            this.resultElems[this.selectedIdx].scrollIntoView(false)
        }, 0) 
    }

    private handleOutsideClick(e: PointerEvent) {
        if (!e.composedPath().includes(this.input) && !e.composedPath().includes(this.resultList)) {
            this.closeResults();
        }
    }

    setValue(label: string, value: string) {
        this.displayValue = label
        this.value = value
        emitEvent(this, 'odj-change', {
            detail: {
                value: this.value
            }
        })
    }


    selectValue(label: string, value: string) {
        this.selectedDisplayValue = label
        this.selectedValue = value
        this.inputValue = label
        this.closeResults()
        this.closeEditMode(true)
    }

    openEditMode() {
        this.inEditMode = true
        setTimeout(() => this.input.focus(), 0)
    }

    closeEditMode(save: boolean) {
        if (save) {
            this.setValue(this.selectedDisplayValue, this.selectedValue)
        }
        this.inEditMode = false
        this.inputValue = this.displayValue
        this.showResults = false
        this.results = []
        this.reportValidity()
    }

    reportValidity() {
        this.invalid = this.required && !this.value

        return !this.invalid
    }

    removeSelection() {
        this.setValue('', '')
    }

    renderResults() {

        if (this.isLoading) {
            return html`<ul class="results results--loading"><li><odj-spinner></odj-spinner></li></ul>`
        }

        const hasNoResultsText = this.hasSlotController.test('no-results-text') || this.noResultsText



        if (this.results.length === 0) {
            if (!this.hasLoadedResults || !hasNoResultsText) {
                return ''
            }
            
            if (hasNoResultsText) {
                return html`<div class="${classMap({
                        results: true,
                        'results--empty': true,
                        'results--open': this.showResults
                    })}"><div class="no-results-wrapper"><span slot="no-results-text"><slot name="no-results-text">${this.noResultsText}</slot></span></div></div>`    
            }
        }

        return html`<ul class="${classMap({
            results: true,
            'results--open': this.showResults
        })}">${this.results.map((r, idx) => html`<li class="${classMap({selected: this.selectedIdx === idx})}" @click="${() => this.selectValue(r.title, r.value)}"><strong>${r.title}</strong><br>${r.subtitle}</li>`)}</ul>`
    }

    reset() {
        this.value = this.getAttribute('value') || ''
        this.displayValue = this.getAttribute('display-value') || ''
        this.inputValue = this.displayValue
        this.inEditMode = false
    }

    renderInputButtons() {
        if (!this.isTouched) {
            return ''
        }
        return html`<odj-button variant="default" @click="${() => this.closeEditMode(false)}" icon="x"></odj-button>`
    
    }


    renderLabelButtons() {
        const disabled = this.disabled || this.readonly

        return html`${this.value ? html`<odj-button variant="danger" ?disabled="${disabled}" @click="${this.removeSelection}" icon="trash"></odj-button>` : ''}<odj-button ?disabled="${disabled}" icon="edit" @click="${this.openEditMode}"></odj-button>`
    }

    renderHelpText() {
        if (this.hasSlotController.test('help-text')) {
            return html`<span slot="help-text"><slot name="help-text"></slot></span>`    
        }
    }


    render() {
        return html`<div class="wrapper"><div class="display-container"><div class="${classMap({label: true, required: this.required})}">${this.label}</div>${!this.inEditMode ? html`<div class="${classMap({'display-wrapper': true, invalid: this.invalid})}"><span class="value">${this.displayValue || 'No selection'}</span> ${this.renderLabelButtons()}</div>` : html`<div class="input-wrapper" @keydown="${this.handleKeydown}" @blur="${this.closeResults}"><div class="input"><odj-input .value="${this.inputValue}" @odj-focus="${this.handleFocus}" ?readonly="${this.readonly}" ?disabled="${this.disabled}" help-text="${ifDefined(this.helpText)}" class="${classMap({invalid: this.invalid})}" @odj-input="${this.handleInput}" @odj-change="${(e: any) => e.stopPropagation()}">${this.renderHelpText()}</odj-input>${this.renderResults()}</div>${this.renderInputButtons()}</div>`}</div></div>`
    }

}