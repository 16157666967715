import { html, LitElement, PropertyValues } from "lit";
import { customElement, property, query, queryAssignedElements, state } from "lit/decorators.js";
import { OdjTableColumn } from "./table-column";
import { tableStyles } from "./table.styles";
import { emitEvent } from "../../internal/event-dispatch";
import { classMap } from "lit/directives/class-map.js";
import { FormSubmitController } from "../../utils/form-control";
import { OdjInput } from "../input";
import { HasSlotController } from "../../internal/slot";
import { ifDefined } from "lit/directives/if-defined.js";


/**
 * Renders a data table with pagination and filters.
 * 
 * Note: This component does not provide functionality for the filters and pagination.
 * 
 * @element odj-table
 * 
 * @slot - Must contain `<odj-table-column>` elements to configure the table columns
 * 
 * @event {{ field: string, direction: string }} odj-table-sort - Fires when the table is sorted.
 * @event {{ field: string, value: string }} odj-table-filter-update - Fires when the user selects a different filter value
 * @event {{ size: number }} odj-table-page-size-change - Fires when the user changes the page size
 * @event {{ page: number }}  odj-table-page-change - Fires when the user navigates to a different page of the table
 * @event {{ value: string | string[] }} odj-change - Fires when the user (de)selects a row. The value is either the identifier of a single-selected row or an array of identifiers with multi-selection. 
 * @event {{ value: string }} odj-table-search-update - Fires when the user searches in the table 
 * 
 * @csspart table - The <table>
 * @csspart table-header-row - The header row
 * @csspart table-header-cell - All Cells in the table head
 * @csspart table-header-cell-${column} - One part is exposed for each column header in the table
 * @csspart table-cell - All cells in the table body
 * @csspart table-cell-${column} - One part is exposed for each column in the table
 */
@customElement('odj-table')
export class OdjTable extends LitElement {

    static override styles = [tableStyles]

    /** The data for this table; should be an array of objects. */
    @property({type: Array})
    data: any[]

    /** The currently selected page */
    @property({type: Number, attribute: 'current-page'})
    currentPage = 1

    /** The page size */    
    @property({type: Number, attribute: 'page-size'})
    pageSize = 10

    /** The total number of items */
    @property({type: Number, attribute: 'total-results'})
    totalResults?: number

    /** Whether the data for the table is currently being loaded */
    @property({type: Boolean})
    loading = false

    /** Whether the pagination control elements should be hidden */
    @property({type: Boolean, attribute: 'hide-pagination'})
    hidePagination = false

    /** The name of the table in the form. */
    @property()
    name: string

    /** Whether table rows can be selected. If enabled, the table will act as a form element. */
    @property({type: Boolean})
    selectable = false

    /** Whether to allow the selection of multiple rows */
    @property({type: Boolean, attribute: 'multiple'})
    allowMultiselect = false

    /** Column which should be used as identifier for a row. Used to keep track of selections. */
    @property({attribute: 'id-column'})
    idColumn: string

    /** Initial value of the search field */
    @property({attribute: 'search-value'})
    searchValue: string

    @property({attribute: false})
    rowLinkGenerator: (row: any) => string

    @queryAssignedElements({ selector: 'odj-table-column', flatten: true })
    columnConfigs: OdjTableColumn[]

    @query('#search')
    searchFieldRef: OdjInput

    @state()
    selectedElems = new Set<string>()

    @state()
    searchable = false

    private initialValue?: string | string[] = undefined

    @property({attribute: false})
    get value(): string | string[] {
        if (!this.selectable) {
            return undefined
        }
        const selectedElems = Array.from(this.selectedElems)
        if (this.allowMultiselect) {
            return selectedElems
        } else {
            return selectedElems.length > 0 ? selectedElems[0] : undefined
        }
    }

    set value(val: string | string[]) {
        if (this.initialValue === undefined && val !== undefined) {
            this.initialValue = val
        }
        this.selectedElems = new Set<string>()

        if (val === undefined) {
            return
        }

        if (Array.isArray(val)) {
            val.forEach(v => this.selectedElems.add(v))
        } else {
            this.selectedElems.add(val)
        }
    }

    private formController: FormSubmitController
    private slotController: HasSlotController

    constructor() {
        super()
        this.formController = new FormSubmitController(this, {
            value: (control: OdjTable) => control.value,
            disabled: (control: OdjTable) => !control.selectable,
        })
        this.slotController = new HasSlotController(this, 'heading')
    }

    private handleUpdate() {
        this.searchable = this.columnConfigs.some(c => c.searchable)
        this.requestUpdate()
    }

    protected willUpdate(_changedProperties: PropertyValues<this>): void {
        if (_changedProperties.has('idColumn') || _changedProperties.has('selectable')) {
            if (this.selectable && !this.idColumn) {
                alert('When making a table selectable, providing the identifier is mandatory. Otherwise, selections will not work.')
            }
        }
    }

    reset() {
        if (this.initialValue !== undefined) {
            this.value = this.initialValue
        }
    }

    private toggleSort(e: Event, col: OdjTableColumn) {

        if (!col.sortable) {
            e.stopPropagation()
            e.preventDefault()
            return
        }

        let newSortDirection
        switch(col.sortDirection) {
            case undefined: 
                newSortDirection = 'desc'
                break
            case 'asc':
                newSortDirection = undefined
                break
            case 'desc':
                newSortDirection = 'asc'
                break
        }

        col.sortDirection = newSortDirection

        emitEvent(this, 'odj-table-sort', {
            detail: {
                field: col.field,
                direction: newSortDirection
            }
        })

        this.columnConfigs.filter(c => c !== col).forEach(c => c.sortDirection = undefined)

        this.requestUpdate()
    }

    private handleFilterUpdate(col: OdjTableColumn, e: CustomEvent) {
        e.stopPropagation()
        emitEvent(this, 'odj-table-filter-update', {
            detail: {
                field: col.field,
                value: e.detail.value
            }
        })

    }

    private handlePageSizeChange(e: CustomEvent) {
        e.stopPropagation()
        this.pageSize = parseInt(e.detail.value, 10)

        emitEvent(this, 'odj-table-page-size-change', {
            detail: {
                size: this.pageSize
            }
        })
    }

    private handlePageChange(e: CustomEvent) {
        e.stopPropagation()
        this.currentPage = parseInt(e.detail.value)

        this.emitPageChange();
    }

    private emitPageChange() {
        emitEvent(this, 'odj-table-page-change', {
            detail: {
                page: this.currentPage
            }
        });
    }

    private handlePrevPage() {
        this.currentPage = this.currentPage - 1
        this.emitPageChange()
    }

    private handleNextPage() {
        this.currentPage = this.currentPage + 1
        this.emitPageChange()
    }

    private handleRowClick(e: MouseEvent | Event, data: any) {
        if (!this.selectable && !this.rowLinkGenerator) {
            return
        }

        if (this.selectable && this.rowLinkGenerator) {
            console.warn('Table has a row link generator and is selectable. The row link always has precedence.')
        }

        if (this.rowLinkGenerator) {
            const targetUrl = this.rowLinkGenerator(data)
            if (e instanceof MouseEvent && (e.ctrlKey || e.metaKey || (e.button && e.button == 1))) {
                window.open(targetUrl, '_blank')
            } else {
                location.href = targetUrl
            }
            return
        }


        if (!this.idColumn) {
            console.warn('Identifier is not configured. Row selection is not supported!')
            return
        }
        
        e.stopPropagation()


        const id = data[this.idColumn]
        const shouldAdd = !this.selectedElems.has(id)

        if (shouldAdd) {
            if (!this.allowMultiselect) {
                this.selectedElems.clear()
            }
            this.selectedElems.add(id)
        } else {
            this.selectedElems.delete(id)
        }

        emitEvent(this, 'odj-change', {
            detail: {
                value: this.value
            }
        })
        this.requestUpdate()
    }

    handleSearch(e: CustomEvent) {
        emitEvent(this, 'odj-table-search-update', {
            detail: {
                value: this.searchFieldRef.value
            }
        })
    }

    render() {
        const hasHeading = this.slotController.test('heading')
        const searchField = this.searchable ? html`<odj-input id="search" placeholder="Search..." type="search" value="${ifDefined(this.searchValue)}" @odj-input="${this.handleSearch}" @odj-change="${(e: CustomEvent) => e.stopPropagation()}"></odj-input>` : ''

        const filterableColumns = Array.from(this.columnConfigs).filter(c => c.filterable);

        const filterColumns = this.renderFilters(filterableColumns)
       

        return html`<slot @slotchange="${this.handleUpdate}" @odj-table-filter-value-change="${this.handleUpdate}"></slot>${hasHeading || this.searchable ? html`<div class="heading-container"><slot name="heading"></slot>${searchField}</div>` 
            : '' }<div class="${classMap({filters: true, 'no-filters': filterColumns.length === 0})}">${filterColumns}</div><table class="${classMap({'odj-table': true, 'odj-table--has-row-link': !!this.rowLinkGenerator})}" part="table"><thead>${this.renderTableHeader()}</thead><tbody>${!this.loading && this.data?.length === 0 ? html`<tr><td colspan="${this.columnConfigs.length}" class="no-results">No results.</td></tr>` : ``} ${this.loading ? html`<tr><td colspan="${this.columnConfigs.length}" class="loading"><odj-spinner></odj-spinner></td></tr>` : ``} ${this.renderTableRows()}</tbody></table>${!this.loading && !this.hidePagination ? this.renderPagination() : ``}`
    }

    private renderTableHeader() {
        const sortIcons = new Map()
        sortIcons.set(undefined, 'arrows-sort')
        sortIcons.set('asc', 'arrow-up')
        sortIcons.set('desc', 'arrow-down')

        return html`<tr part="table-header-row">${this.selectable && this.allowMultiselect ? html`<th style="width:30px"></th>` : ''} ${this.columnConfigs.map(col => html`<th @click="${(e: Event) => this.toggleSort(e, col)}" class="${classMap({sortable: col.sortable, 'is-sorted': col.sortDirection !== undefined})}" part="table-header-cell table-header-cell-${col.field}">${col.label} ${col.sortable ? html`<odj-icon icon="${sortIcons.get(col.sortDirection)}"></odj-icon>` : ''}</th>`)}</tr>`
    }

    private renderTableRows() {
        return !this.loading ? this.data?.map((row: any) => html`<tr @click="${(e: MouseEvent) => this.handleRowClick(e, row)}" class="${classMap({
                    selected: this.selectable && !this.allowMultiselect && this.selectedElems.has(row[this.idColumn]),
                })}">${this.selectable && this.allowMultiselect ? html`<td><odj-checkbox value="${row[this.idColumn]}" label="${''+this.columnConfigs[0]?.valueFormatter(row)}" hide-label standalone .checked="${this.selectedElems.has(row[this.idColumn])}" @click="${(e: Event) => e.stopPropagation()}" @odj-change="${(e: Event) => this.handleRowClick(e, row)}"></odj-checkbox></td>` : ''} ${this.columnConfigs.map(col => html`<td part="table-cell table-cell-${col.field}">${col.valueFormatter(row)}</td>`)}</tr>`) : html``;
    }

    private renderFilters(filterableColumns: OdjTableColumn[]) {
        return filterableColumns.map(c => {
            switch (c.filterAppearance) {
                case 'select':
                    return html`<odj-select label="${c.label}" @odj-change="${(e: CustomEvent) => this.handleFilterUpdate(c, e)}" searchable ?disabled="${c.filtersLoading}"><odj-option value="" ?selected="${c.filtersLoading || !c.initialFilterValue}">${c.filtersLoading ? 'Loading...' : 'Select...'}</odj-option>${c.filterValues.map(f => html`<odj-option ?selected="${c.initialFilterValue === f.value}" value="${f.value}">${f.label}</odj-option>`)}</odj-select>`;
                case 'radio-buttons':
                    return html`<odj-radio-group label="${c.label}" @odj-change="${(e: CustomEvent) => this.handleFilterUpdate(c, e)}" ?disabled="${c.filtersLoading}">${c.filtersLoading ? html`<div class="loading-filter">Loading...</div>` : ''} ${c.filterValues.map(f => html`<odj-radio-button ?checked="${c.initialFilterValue === f.value}" value="${f.value}">${f.label}</odj-radio-button>`)}</odj-radio-group>`;
            }

        });
    }

    private renderPagination() {
        const totalResults = this.totalResults ?? (this.data?.length ?? 0)
        const numPages = Math.max(Math.ceil(totalResults / this.pageSize), 1)
        const fromRange = totalResults === 0 ? 0 : (this.pageSize * (this.currentPage - 1) + 1)

        return html`<div class="pagination"><div class="items-per-page">Items per page:<odj-select @odj-change="${this.handlePageSizeChange}" standalone><odj-option ?selected="${this.pageSize === 10}">10</odj-option><odj-option ?selected="${this.pageSize === 25}">25</odj-option><odj-option ?selected="${this.pageSize === 50}">50</odj-option></odj-select></div><div class="display-info">${fromRange}-${Math.min(this.pageSize * this.currentPage, totalResults)} of ${totalResults} items</div><div class="current-page"><odj-select standalone value="${this.currentPage}" @odj-change="${this.handlePageChange}">${[...Array(numPages).keys()].map(i => html`<odj-option ?selected="${this.currentPage === i + 1}">${i + 1}</odj-option>`)}</odj-select>of page ${numPages}<div class="pagination-buttons"><odj-button variant="tertiary" ?disabled="${this.currentPage === 1}" @click="${this.handlePrevPage}"><odj-icon icon="chevron-left-small"></odj-icon></odj-button><odj-button variant="tertiary" ?disabled="${this.currentPage === numPages}" @click="${this.handleNextPage}"><odj-icon icon="chevron-right-small"></odj-icon></odj-button></div></div></div>`
    }
}