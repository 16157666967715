import {LitElement, html, css} from 'lit';
import {customElement, query} from 'lit/decorators.js';
import {emitEvent} from '../../internal/event-dispatch';

/**
 * @slot - Reserved for slotting tab elements.
 *
 * @fires {{value: string}} odj-change - On every selection dispatched event which contains the selected elements' `select-value` or `value` attribute as value.
 */
@customElement('odj-tab-select')
export class OdjTabSelect extends LitElement {
	static styles = css`:host{display:flex;flex-wrap:wrap;gap:1.5rem;border-bottom:1px solid var(--odj-black-light)}`

	@query('slot')
	_optionSlot: HTMLSlotElement;

	get selected() {
		return this._optionSlot.assignedElements().filter(ele => ele.hasAttribute('selected'))[0];
	}

    handleClick(e: Event) {
        const evtTarget = e.target as HTMLElement

        // We need to set the event target to one of the direct children of the tab select in order to rely on its attributes
        const tab = e.composedPath().find((elem: HTMLElement) => elem.parentNode === this) as HTMLElement
        
        const curr = this.selected
        if (curr) {
            curr.removeAttribute('selected')
        } 

        tab.setAttribute('selected', '')
        emitEvent(this, 'odj-change', {
            detail: {
                value: tab.getAttribute('select-value') || tab.getAttribute('value')
            }
        });
    }

	render() {
		return html`<slot @click="${this.handleClick}"></slot>`
	}
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-tab-select": OdjTabSelect;
    }
}