import {css, html, LitElement} from "lit";
import {customElement, property} from "lit/decorators.js";
import {OdjStep} from "./step";
import {emitEvent} from "../../internal/event-dispatch";

/**
 * A stepper in ODJ style.
 *
 * @element odj-stepper
 *
 * @event {{value: OdjStep}} odj-step-change - Dispatched every time the user selects a step if the stepper is clickable.
 *
 * @slot - Must contain <odj-step> elements
 *
 */
@customElement('odj-stepper')
export class OdjStepper extends LitElement {

    static override styles = css`:host{display:block}.clickable ::slotted(odj-step){cursor:pointer}`

    /** the name of the active step */
    @property({attribute: 'active-step'})
    activeStep: string

    /** activate steps to be clickable */
    @property({type: Boolean})
    clickable: boolean

    private steps: NodeListOf<OdjStep>

    constructor() {
        super()
    }

    firstUpdated() {
        this.steps = this.querySelectorAll('odj-step')
    }

    updated() {
        if (this.activeStep) {
            this.steps.forEach(step => {
                step.partiallyDone = false
                step.done = false
                step.active = false
                step.error = false
            })
            for (let i = 0; i < this.steps.length; i++) {
                if (this.steps[i].name == this.activeStep) {
                    for (let z = i - 1; z >= 0; z--) {
                        this.steps[z].done = true
                    }
                    this.updateParentNode(this.steps[i])
                    this.steps[i].active = true
                    break
                }
            }
        }
    }

    render() {
        return html`<div class="${this.clickable ? 'clickable' : ''}"><slot @click="${this.handleStepClick}"></slot></div>`
    }

    private updateParentNode(step: OdjStep) {
        let parent = step.parentElement
        if (parent.tagName == 'ODJ-STEP') {
            try {
                let parentStep = this.getParentStep(parent.getAttribute('name'))
                parentStep.done = false
                parentStep.partiallyDone = true
                this.updateParentNode(parentStep)
            } catch (err) {
                console.warn(err)
            }
        }
    }

    private getParentStep(stepName: string): OdjStep {
        for (let i = 0; i < this.steps.length; i++) {
            if (this.steps[i].name == stepName) {
                return this.steps[i]
            }
        }

        throw new Error('not found')
    }

    private handleStepClick(e: { target: OdjStep; }) {
        if (this.clickable) {
            this.activeStep = e.target.name
            emitEvent(this, 'odj-step-change', {detail: e.target})
        }
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "odj-stepper": OdjStepper;
    }
}