import { LitElement, PropertyValues, TemplateResult } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { emitEvent } from "../../internal/event-dispatch";
import { FilterValue } from "./filter-values";


/**
 * Configures either `<odj-table>` or `<odj-remote-table>`.
 * 
 * @element odj-table-column
 */
@customElement('odj-table-column')
export class OdjTableColumn extends LitElement {

    /** Readable label of the table column */
    @property()
    label: string

    /** Name of the field in the data source */
    @property()
    field: string

    /** Provides the value for a given table row. Can return either a string or a Lit template literal. */
    @property({attribute: false})
    valueFormatter: (row: any) => TemplateResult<1> = (row: any) => row[this.field]

    /** Whether the column is sortable */
    @property({type: Boolean})
    sortable: boolean

    /** Data type of this column, used to decide how to sort */
    @property()
    type: 'string' | 'number' | 'any' = 'string'

    /** Custom sort function, used when `type` is set to to `any` */
    @property({attribute: false})
    sortFunction: (a: any, b: any) => number

    /** Whether it is possible to filter by this column */
    @property({type: Boolean})
    filterable: boolean

    @property({type: Boolean})
    searchable: boolean

    /** Configures the data source for the filters */
    @property({attribute: 'filter-mode'})
    filterMode: 'from-data' | 'list'
    
    /** The list of filter values for this column; will be filled automatically by `odj-remote-table` if the filter mode is `from-data`. */
    @property({type: Array, attribute: false})
    filterValues: FilterValue[] = []

    @property({reflect: true, attribute: 'initial-filter-value'})
    initialFilterValue?: string

    @property({attribute: 'filters-loading', type: Boolean})
    filtersLoading = false

    /** The appearance of the filter */
    @property({attribute: 'filter-appearance'})
    filterAppearance: 'select' | 'radio-buttons' = 'select'

    /** Allows using another field for the label when rendering the filter */
    @property({attribute: 'filter-label-field'})
    filterLabelField?: string

    @state()
    sortDirection: string | undefined

    protected updated(_changedProperties: PropertyValues<this>): void {
        if (_changedProperties.has('filterValues')) {
            emitEvent(this, 'odj-table-filter-value-change')
        }
    }
}