type Icon = {
    ok: boolean
    svg: string
}

const iconCache = new Map<String, Promise<Icon>>()

export async function fetchIcon(url: string): Promise<Icon> {

    if (iconCache.has(url)) {
        return iconCache.get(url)
    }

    const icon = fetch(url, {mode: 'cors', credentials: 'same-origin'}).then(async (res) => {
        return {
            ok: res.ok,
            svg: await res.text()
        }
    }).catch(err => {
        console.warn(err);
        return {
            ok: false,
            svg: ''
        };
    })

    iconCache.set(url, icon)
    return icon
}