import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';

@customElement('odj-table-wrapper')
export class OdjTableWrapper extends LitElement {
	static styles = css`table{width:100%;border-spacing:0 .25rem}table thead{text-align:left}thead tr{background:#292929;color:#d9d9d9}thead tr th{border-bottom:.25rem solid var(--odj-background-color)}tbody tr{background:#151515;color:#9c9c9c}td,th{padding:.5rem}`

	firstUpdated() {
		this.renderRoot.append(...this.childNodes)
	}

	render() {
		return html``
	}
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-table-wrapper": OdjTableWrapper;
    }
}