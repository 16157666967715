import { html, LitElement, TemplateResult } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { componentStyles } from "./toast.styles";

/**
 * Renders a toast notification.
 * 
 * @element odj-toast
 * 
 * @slot - Provide the toast message
 * @slot action - Provide an action
 * 
 * @csspart close-button - The default close button
 * @csspart message - The container for the toast message
 */
@customElement('odj-toast')
export class OdjToast extends LitElement {

    static override styles = componentStyles

    /** Variant */
    @property({reflect: true, type: String})
    variant:  'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'light' | 'dark' = 'success'

    /** Delay in milliseconds after which the alert disappears */
    @property({reflect: true, type: Number})
    delay: number | undefined

    @state()
    shown = false

    @query('.toast')
    toastElem: HTMLElement

    constructor() {
        super()
    }

    /** Closes the toast */
    public close() {
        this.toastElem.style.maxHeight = this.toastElem.offsetHeight + 'px'
        this.shown = false
        this.toastElem.addEventListener('transitionend', () => {
            this.remove()
        })
    }

    firstUpdated() {
        this.updateComplete.then(() => {
            setTimeout(() => {
                this.shown = true
            }, 0)
            if (this.delay !== undefined) {
                // We don't support updating the delay afterwards
                setTimeout(() => {
                    this.close()

                }, this.delay)
            }
        })

    }

    render(): TemplateResult {
        const classes = {
            'toast': true,
            'toast--hidden': !this.shown
        }

        return html`<div class="${classMap(classes)}"><div class="toast-body toast--${this.variant}"><div class="toast-message" part="message"><slot></slot></div><slot name="action"><odj-button part="close-button" class="close-button" type="button" variant="icon" @click="${() => this.close()}"><odj-icon icon="x-small"></odj-icon></odj-button></slot></div></div>`
    }

}

declare global {
    interface HTMLElementTagNameMap {
      "odj-toast": OdjToast;
    }
}