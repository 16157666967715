import { LitElement, html, TemplateResult } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { emitEvent } from '../../internal/event-dispatch';
import { FormSubmitController } from '../../utils/form-control';
import { renderFormWrapper } from "../../internal/form-wrapper";
import { getElementId } from '../../internal/id-generator';
import { HasSlotController } from '../../internal/slot';
import { watch } from '../../internal/watch';
import { getErrorMessage } from '../../utils';
import { OdjSelectOption } from './select-option';
import { componentStyles } from './select.styles';

/**
 * A dropdown select menu in ODJ style.
 *
 * @element odj-select
 *
 * @event {{value: string}} odj-change - Dispatched every time the user selects a new value; contains the selected elements' `value` attribute as value.
 * 
 * @slot - Must contain <odj-option> elements
 * @slot label - Allows formatting of the label
 * @slot help-text - Allows formatting of the help text
 *
 */
@customElement('odj-select')
export class OdjSelect extends LitElement {

    static override styles = componentStyles

    /** Name of the field used for form submission */
    @property({ reflect: true, attribute: 'name', type: String })
    name?: string

    /** Value of the field */
    @property()
    public value?: string

    /** Label of the field */
    @property()
    public label?: string

    /** Help text to assist the user with this field */
    @property({ reflect: true, attribute: 'help-text', type: String })
    public helpText?: string

    /** Disables the input */
    @property({ type: Boolean, reflect: true }) 
    disabled = false;

    /** Makes the input readonly */
    @property({ type: Boolean, reflect: true })
    readonly = false;

    /** Whether the field is required for form submission */
    @property({reflect: true, type: Boolean})
    required = false

    /** Whether the field is in an invalid state */
    @property({ type: Boolean, reflect: true })
    invalid = false

    /** Pre-fills the error message of the form field */
    @property({reflect: false, attribute: 'error-text', type: String})
    errorText: string

    /** Renders the field at full width without form validation status */
    @property({ type: Boolean, reflect: true, attribute: 'standalone'})
    standalone = false

    @property()
    placeholder: string

    @property({reflect: true, type: Boolean, attribute: 'searchable'})
    searchable: boolean

    @state()
    private selection?: OdjSelectOption

    @state()
    private isOpen = false

    @state()
    private selectedIndex?: number

    @state()
    private isTouched = false

    @state()
    private hasReportedValidity = false

    @query('.odj-select')
    private selectContainer: HTMLDivElement

    @query('.items')
    private itemContainer: HTMLDivElement

    @query('.search-input')
    private searchInput: HTMLInputElement


    get hasValidation(): boolean {
        return !this.standalone && (this.required || this.hasAttribute('invalid'))
    }

    @state()
    private inputId: string
    
    @state()
    private labelId: string

    @state()
    private search: string

    @state()
    private noResults = false

    private formController: FormSubmitController
    private hasSlotController: HasSlotController
    private mutationObserver: MutationObserver

    constructor() {
        super()
        this.formController = new FormSubmitController(this)
        this.hasSlotController = new HasSlotController(this, 'label', 'help-text')

        this.inputId = 'select-' + getElementId()
        this.labelId = 'label-' + getElementId()
        this.handleOutsideClick = this.handleOutsideClick.bind(this)
        this.handleKeydown = this.handleKeydown.bind(this)

        this.mutationObserver = new MutationObserver((mutations) => {
            this.syncFromDocument()
        })
    }

    override connectedCallback(): void {
        super.connectedCallback()

        this.addEventListener('keydown', this.handleKeydown)


        this.mutationObserver.observe(this, {
            subtree: true,
            childList: true,
        })

        // We need to wait until the children have been initialized
        // setTimeout(, 0) should work in most cases
        setTimeout(() => {
            this.syncFromDocument();
        }, 0)
    }


    private syncFromDocument() {
        const options = this.getOptions();

        const selected = options.filter(o => o.hasAttribute('selected'));

        if (selected.length > 0) {
            this.selection = selected[0].cloneNode(true) as OdjSelectOption;
        } else {
            this.initializeEmptySelection();
        }

        this.selection.setAttribute('is-current-selection', '');
        this.value = this.selection.getValue();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback()

        this.removeEventListener('keydown', this.handleKeydown)
        document.removeEventListener('click', this.handleOutsideClick)

        this.mutationObserver?.disconnect()
    }

    private initializeEmptySelection() {
        this.selection = new OdjSelectOption();
        this.selection.isPlaceholder = true;
        this.selection.value = undefined;

        if (this.placeholder) {
            this.selection.innerText = this.placeholder;
        }
    }

    /** Resets the textarea to its initial state and removes any validation state */
    reset() {
        this.syncFromDocument()
        this.invalid = false
        this.removeAttribute('invalid')
        this.hasReportedValidity = false
        this.isTouched = false
        this.errorText = ''
    }
    

    focus(options?: FocusOptions): void {
        this.selectContainer.focus()
    }

    private openDropdown() {
        this.isOpen = true

        if (this.searchable) {
            this.searchInput.focus()
        }
        
        document.addEventListener('click', this.handleOutsideClick)
    }

    private closeDropdown() {
        this.isOpen = false
        this.isTouched = true

        if (this.searchable) {
            this.searchInput.value = ''
            this.search = ''
            this.searchInput.blur()
            this.updateItemFilter()
        }

        document.removeEventListener('click', this.handleOutsideClick)

        if (this.hasReportedValidity || this.isTouched) {
            this.reportValidity()
        }        
    }

    private selectNextElement() {
        const options = this.getVisibleOptions()

        if (options.length === 0) {
            return
        }

        if (this.selectedIndex === undefined) {
            this.selectedIndex = -1
        } 

        if (this.selectedIndex + 1 < options.length) {
            this.selectElement(this.selectedIndex + 1)
        }
    }


    private selectPreviousElement() {
        const options = this.getVisibleOptions()

        if (options.length === 0) {
            return
        }

        if (this.selectedIndex === undefined) {
            this.selectedIndex = options.length - 1
        } 

        if (this.selectedIndex - 1 >= 0) {
            this.selectElement(this.selectedIndex - 1)
        }
    }

    private selectElement(idx: number) {
        this.selectedIndex = idx

        const options = this.getVisibleOptions()
        for (let opt of options) {
            opt.active = false
        }

        if (idx !== undefined) {
            const selectedOption = options[idx];

            selectedOption.active = true
            
            // Build some useful scrolling behavior
            const optionOffset = selectedOption.offsetTop + selectedOption.offsetHeight;
            const containerOffset = this.itemContainer.offsetHeight + this.itemContainer.scrollTop;
            if (optionOffset >= containerOffset || options[idx].offsetTop <= this.itemContainer.scrollTop) {
                options[idx].scrollIntoView(false)
            }
        }
    }

    maybeCheckCurrentSelection() {
        if (this.selectedIndex !== undefined) {
            const opt = this.querySelector<OdjSelectOption>('odj-option[active]')
            this.checkOption(opt)
            this.selectElement(undefined)
        }
        this.closeDropdown()
    }

    private handleOutsideClick(e: PointerEvent) {
        if (!e.composedPath().includes(this)) {
            this.closeDropdown();
        }
    }

    private handleKeydown(e: KeyboardEvent) {
        if (e.key === 'ArrowDown') {
            e.preventDefault()
            if (!this.isOpen) {
                this.openDropdown()
                this.selectElement(0)
                return;
            }

            this.selectNextElement()

        } else if (e.key === 'ArrowUp') {
            this.selectPreviousElement()
            e.preventDefault()
        } else if (e.key === 'Escape' || e.key === 'Esc') {
            this.selectElement(undefined)
            this.closeDropdown()
        } else if (e.key === 'Enter') {
            this.maybeCheckCurrentSelection()
            this.closeDropdown()
        } else if (e.key === 'Tab') {
            this.closeDropdown()
        }

    }

    private getOptions(): OdjSelectOption[] {
        return [...this.querySelectorAll<OdjSelectOption>(':scope > odj-option')]
    }

    private getVisibleOptions(): OdjSelectOption[]  {
        return this.getOptions().filter(o => o.style.display !== 'none')
    }


    private toggleMenu(_: Event) {
        if (this.isOpen) {
            this.closeDropdown()
        } else {
            this.openDropdown()
        }
    }
    
    private handleSearchInput(e: Event) {
        this.search = this.searchInput.value

        this.updateItemFilter()

    }

    private updateItemFilter() {
        if (!this.searchable) {
            return
        }

        this.selectedIndex = undefined
        this.noResults = true
        for (let item of this.getOptions()) {
            if (!item.textContent.toLowerCase().includes(this.search.toLowerCase())) {
                item.style.display = 'none'
            } else {
                item.style.display = 'inherit'
                this.noResults = false
            }
            item.removeAttribute('active')
        }
    }

    private handleSearchKeydown(e: KeyboardEvent) {

        // Prevent form submit
        if (e.key === 'Enter') {
            e.preventDefault()
        }

    }

    private handleSelection(e: PointerEvent) {
        // Make sure we support options with additional formatting where the click target might not be the odj-option itself
        const odjOption = (e.target as HTMLElement).closest('odj-option')
        if (odjOption === null) {
            return
        }
        this.checkOption(odjOption as OdjSelectOption);
        this.closeDropdown()
    }

    private checkOption(checkedOption: OdjSelectOption, fireEvent = true) {
        const clonedOption = checkedOption.cloneNode(true) as OdjSelectOption
        const allOptions = this.getOptions();

        for (let option of allOptions) {
            option.selected = false;
        }

        this.selection = clonedOption;
        this.selection.setAttribute('is-current-selection', '');
        checkedOption.selected = true;
        this.value = checkedOption.getValue();

        if (fireEvent) {
            emitEvent(this, 'odj-change', {
                detail: {
                    value: this.value
                }
            })
        }

    }

    @watch('value', {waitUntilFirstUpdate: true})
    handleValueChange(props: any) {
        const options = this.getOptions()

        let foundOption = false
        for (let o of options) {       
            if (o.getValue() === this.value) {
                this.checkOption(o, false)
                foundOption = true
                break
            }
        }

        if (!foundOption) {
            this.value = ''
            this.initializeEmptySelection()
        }
    }

    reportValidity() {
        this.hasReportedValidity = true
        if (this.required && !this.value) {
            this.invalid = true
            this.errorText = getErrorMessage('valueMissing')
            return false
        }
        this.invalid = false
        this.errorText = ''
        return true
    }

    private renderControl(shouldShowStatusIndicator: boolean) {
        const classes = {
            'odj-select': true,
            'odj-select--open': this.isOpen,
            'odj-select--valid': shouldShowStatusIndicator && !this.invalid,
            'odj-select--invalid': shouldShowStatusIndicator && this.invalid,
            'odj-select--standalone': this.standalone,
            'odj-select--readonly': this.readonly,
            'odj-select--disabled': this.disabled,
            'odj-select--has-search-input': this.searchable && (this.search || '') !== ''
        };

        const control = html`<div tabindex="${this.hasAttribute('tabindex') ? Number(this.getAttribute('tabindex')) : 0}" class="${classMap(classes)}" aria-labelledby="${this.labelId}" part="control"><div class="current-selection" style="z-index:${this.isOpen ? 1000 : 800}" @click="${this.toggleMenu}"><div class="item">${this.selection?.icon ? html`<div class="icon"><odj-icon icon="${this.selection?.icon}"></odj-icon></div>` : ''}<div class="label">${this.selection?.innerText}</div></div>${this.searchable ? html`<input type="text" class="search-input" @input="${this.handleSearchInput}" @keydown="${this.handleSearchKeydown}">` : ``}<div class="chevron"><odj-icon icon="${this.isOpen ? 'chevron-up-small' : 'chevron-down-small'}"></odj-icon></div></div><div @mouseup="${this.handleSelection}" style="z-index:${this.isOpen ? 950 : 750}" class="${classMap({ items: true, 'open': this.isOpen })}"><slot></slot>${this.searchable && this.noResults ? html`<div class="no-results">No results</div>` : ``}</div></div>`;
        return control;
    }


    override render(): TemplateResult {
        /* We show the status indicator under the following conditions:
         *   - The field has validation enabled (it's not [standalone] and it's required)
         +   - The field has been previously touched by the user or form validation was triggered before
         */
        const shouldShowStatusIndicator = this.hasValidation && (this.isTouched || this.hasReportedValidity || this.hasAttribute('invalid'));
        

        const hasLabel = this.hasSlotController.test('label') || !!this.label
        const hasHelpText = this.hasSlotController.test('help-text') || !!this.helpText

        return renderFormWrapper({
                ref: this,
                required: this.required,
                invalid: this.invalid,
                helpText: this.helpText,
                label: this.label,
                hasLabel: hasLabel,
                hasHelpText: hasHelpText,
                id: this.inputId,
                labelId: this.labelId,
                standalone: this.standalone,
                errorText: this.errorText
            },
                this.renderControl(shouldShowStatusIndicator)
            )

    }
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-select": OdjSelect;
    }
}
