import {css, html, LitElement} from "lit";
import {customElement, property} from "lit/decorators.js";
import {classMap} from "lit/directives/class-map.js";

/**
 * A step in ODJ style.
 *
 * @element odj-step
 *
 * @property hidden will hide the step
 *
 */
@customElement('odj-step')
export class OdjStep extends LitElement {

    static override styles = css`:host{display:block;font-family:'Open Sans';font-size:16px;line-height:22px}:host([hidden]){display:none}:host>span{display:flex;margin-bottom:22px}odj-icon{display:block;font-size:1.5rem}.active-indicator,.status-indicator{display:inline-block;min-width:34px}.step-name{flex-grow:2;color:#a3a3a3}.step-active .step-name{color:#fff;font-weight:700}.step-done .step-name{color:#fff}.open-indicator{box-sizing:border-box;width:20px;height:20px;background:#222;border:1px solid #5a6268;border-radius:50%;margin:2px}.open-indicator--partially-done{border-color:#fff}.open-indicator--done{border-color:var(--odj-success-color);background-color:var(--odj-success-color)}.open-indicator--error{border-color:red;background-color:red}.open-indicator odj-icon{margin:-3px 0 0 -3px;color:#fff}`

    /** the step name */
    @property()
    name: string

    /** the level of the step */
    @property({type: Number})
    level: number

    /** if step currently active */
    @property({type: Boolean, reflect: true})
    active: boolean

    /** if step is done */
    @property({type: Boolean, reflect: true})
    done: boolean

    /** if step is partially done */
    @property({attribute: 'partially-done', type: Boolean, reflect: true})
    partiallyDone: boolean

    /** if step has error */
    @property({type: Boolean, reflect: true})
    error: boolean

    constructor() {
        super()
        this.level = 0
    }

    firstUpdated() {
        this.setLevelToSubSteps()
    }

    render() {
        let statusIcon = html``
        let statusClass = ''
        if (this.error) {
            statusIcon = html`<odj-icon icon="x-small"></odj-icon>`
            statusClass = 'open-indicator--error'
        } else if (this.partiallyDone) {
            statusIcon = html`<odj-icon icon="check-small"></odj-icon>`
            statusClass = 'open-indicator--partially-done'
        } else if (this.done) {
            statusIcon = html`<odj-icon icon="check-small"></odj-icon>`
            statusClass = 'open-indicator--done'
        }

        const stepClasses = {
            'step-active': this.active,
            'step-done': this.done || this.partiallyDone || this.error
        }

        return html`<span class="${classMap(stepClasses)}"><span class="active-indicator">${this.active ? html`<odj-icon icon="chevron-right-small"></odj-icon>` : ''} </span><span class="step-name" style="padding-left:${(this.level * 10) + 'px'}">${this.name} </span><span class="status-indicator"><div class="open-indicator ${statusClass}">${statusIcon}</div></span></span><slot @slotchange="${this.setLevelToSubSteps}"></slot>`
    }

    private setLevelToSubSteps() {
        const steps = this.shadowRoot.querySelector('slot').assignedElements()
        steps.forEach(step => {
            step.setAttribute('level', (this.level + 1).toString())
        })
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "odj-step": OdjStep;
    }
}