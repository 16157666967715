import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

/** 
 * A breadcrumb
 * 
 * @slot - Contains the items of the breadcrumb navigation
*/
@customElement('odj-breadcrumb')
export class OdjBreadcrumb extends LitElement {

    static override readonly styles = css`:host{display:block}nav{display:flex;gap:.25rem}::slotted(odj-breadcrumb-item)::before{content:url(data:image/svg+xml,%3Csvg%20width%3D%2217%22%20height%3D%2216%22%20viewBox%3D%220%200%2017%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M6.19399%204.47291C6.13218%204.53458%206.08315%204.60784%206.0497%204.68849C6.01624%204.76914%205.99902%204.8556%205.99902%204.94291C5.99902%205.03022%206.01624%205.11668%206.0497%205.19732C6.08315%205.27797%206.13218%205.35123%206.19399%205.41291L8.77999%207.99891L6.19399%2010.5849C6.07565%2010.7108%206.01097%2010.8779%206.01364%2011.0506C6.01632%2011.2234%206.08615%2011.3884%206.20834%2011.5106C6.33053%2011.6327%206.49548%2011.7026%206.66825%2011.7053C6.84103%2011.7079%207.00807%2011.6432%207.13399%2011.5249L10.194%208.46491C10.2558%208.40323%2010.3048%208.32997%2010.3383%208.24932C10.3717%208.16868%2010.3889%208.08222%2010.3889%207.99491C10.3889%207.9076%2010.3717%207.82114%2010.3383%207.74049C10.3048%207.65984%2010.2558%207.58658%2010.194%207.52491L7.13399%204.46491C7.00761%204.3426%206.83821%204.27489%206.66235%204.27639C6.48649%204.27789%206.31826%204.34847%206.19399%204.47291Z%22%20fill%3D%22white%22%20fill-opacity%3D%220.6%22%2F%3E%0A%3C%2Fsvg%3E);margin-top:.2rem;margin-bottom:-.2rem;padding-right:.25rem;float:left}::slotted(odj-breadcrumb-item:first-child)::before{content:"";margin:0;padding:0}`

    render() {
        return html`<nav aria-label="Breadcrumb"><slot></slot></nav>`
    }

}