declare const WEBPACK_DEV_MODE: boolean | undefined

let basePath = 'https://storage.googleapis.com/odjui-register-prod-gcpbucket/components'

// Load assets locally for docs and local development environment
if ((typeof WEBPACK_DEV_MODE !== 'undefined' && WEBPACK_DEV_MODE) || location.hostname === 'ui-components.prod.odjui.sys.odj.cloud') {
    basePath = ''
}

export function setBasePath(url: string) {
    basePath = url    
}

export function getBasePath(): string {
    // Remove trailing slashes
    return basePath.replace(/\/$/, '')
}