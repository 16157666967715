import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";

/** 
 * A breadcrumb item
 * 
 * @slot - Contains the label of the breadcrumb item
*/
@customElement('odj-breadcrumb-item')
export class OdjBreadcrumbItem extends LitElement {

    static override readonly styles = css`:host{display:block}`

    /** Optional link */
    @property()
    href: string | undefined

    /** The currently active breadcrumb item */
    @property({type: Boolean})
    active = false

    render() {
        const ariaCurrentAttr = this.active ? 'page' : undefined;
        return html`${this.href ? html`<odj-button variant="link" type="link" href="${this.href}" aria-current="${ifDefined(ariaCurrentAttr)}"><slot></slot></odj-button>` : html`<span><slot></slot></span>`}`


    }
}