import { OdjToastContainer } from "./toast-container"

export function createToast(container: OdjToastContainer, message: string, variant?: string, delay?: number, actionText?: string, actionLink?: string) {
 
    if (container === null) {
        console.warn('No <odj-toast-container> found on page. Not rendering toast.')
        return
    }
    
    const template = document.createElement('template')

    template.innerHTML = `
        <odj-toast variant="${variant}" ${delay ? `delay="${delay}"` : ''}>
            ${message}
            ${actionLink ? `<odj-button slot="action" type="link" variant="link" href="${actionLink}">${actionText}</odj-button>` : ''}
        </odj-toast>`


    container.append(template.content.cloneNode(true))
}