/** Report validity of a form */
export function reportValidity(form: HTMLFormElement): boolean {

    const elems = form.querySelectorAll('*')

    let validity = true
    for (const elem of elems) {
        if ((elem as any).reportValidity && typeof (elem as HTMLInputElement).reportValidity === 'function') {
            const localValidity = (elem as HTMLInputElement).reportValidity()
            validity = validity && localValidity
        }
    }
    return validity
}

/** Report validity of a form and run asynchronous validators */
export async function waitForValidity(form: HTMLFormElement): Promise<boolean> {

    const elems = form.querySelectorAll('*')

    let validity = true
    const asyncValidations = []
    for (const elem of elems) {
        // We trigger the asynchronous validation first, but wait for the result after it has been triggered
        // for all elements of the form
        if ((elem as any).waitForValidity && typeof (elem as any).waitForValidity === 'function') {
            asyncValidations.push((elem as any).waitForValidity())
            continue
        }

        if ((elem as any).reportValidity && typeof (elem as HTMLInputElement).reportValidity === 'function') {
            const localValidity = (elem as HTMLInputElement).reportValidity()
            validity = validity && localValidity
        }
    }

    if (asyncValidations.length > 0) {
        const validationStates = await Promise.all(asyncValidations)
        validity = validity && validationStates.every((isValid) => isValid)
    }

    return validity
}


export function getErrorMessage(errorType: keyof ValidityState, elem?: unknown) {
    const errorMap: {
        [k in keyof ValidityState]?: string
    } = {
        badInput: 'Invalid value.',
        patternMismatch: 'Value does not match pattern.',
        valueMissing: 'Value is required.',
        rangeOverflow: 'Value is too big.',
        rangeUnderflow: 'Value is too small.',
        tooLong: 'Value is too long.',
        tooShort: 'Value is too short.',
        typeMismatch: 'Value has the wrong type.',
    }

    if (elem instanceof HTMLInputElement) {
        if (errorType === 'typeMismatch') {
            switch(elem.type) {
                case 'email':
                    return 'Invalid email.'
                case 'url':
                    return 'Invalid URL.'
                default:
                    return 'Invalid input for this field.'
            }
        } else if (errorType === 'patternMismatch') {
            return `Value does not match pattern "${elem.pattern}".`
        } else if (errorType === 'customError') {
            return elem.validationMessage
        } else if (errorType === 'stepMismatch') {
            return `Step mismatch, only ${elem.step || 'whole number'} steps are allowed.`
        }
    }
    return errorMap[errorType]
}


export type ValidationResult = {
    isValid: boolean
    message: string
}

export type RegistryEntry = {
    async: boolean
    validator: SyncValidator | AsyncValidator 
}

export type SyncValidator = (value: string, element: unknown) => ValidationResult
export type AsyncValidator = (value: string, element: unknown) => Promise<ValidationResult>

const validationRegistry = new Map<string, RegistryEntry>()

export function addValidator(key: string, validator: SyncValidator) {

    if (validationRegistry.has(key)) {
        console.warn(`Validator with key ${key} is already registered and will be overridden`)
    }

    validationRegistry.set(key, {async: false, validator})
}

export function addAsyncValidator(key: string, validator: AsyncValidator) {

    if (validationRegistry.has(key)) {
        console.warn(`Validator with key ${key} is already registered and will be overridden`)
    }

    validationRegistry.set(key, {async: true, validator})
}

export function wrapValidator(validator: SyncValidator): RegistryEntry {
    return {
        async: false,
        validator: validator,
    }
}

export function wrapAsyncValidator(validator: AsyncValidator): RegistryEntry {
    return {
        async: true,
        validator: validator,
    }
}

export function getSyncValidator(key: string | RegistryEntry): SyncValidator | null {
    if (typeof key === 'string') {
        if (validationRegistry.has(key)) {
            const entry = validationRegistry.get(key)
            if (!entry.async) {
                return validationRegistry.get(key).validator as SyncValidator
            }
        }
        return null
    } else if ('async' in key && !key.async) {
        return key.validator as SyncValidator        
    }
    return null  
}


export function getAsyncValidator(key: string | RegistryEntry): AsyncValidator | null {
    if (typeof key === 'string') {
        if (validationRegistry.has(key)) {
            const entry = validationRegistry.get(key)
            if (entry.async) {
                return validationRegistry.get(key).validator as AsyncValidator
            }
        }
        return null
    } else if ('async' in key && key.async) {
        return key.validator as AsyncValidator        
    }
    return null
}
