import { html, LitElement } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { componentStyles } from "./tooltip.styles";

import { arrow, computePosition, flip, offset, autoUpdate, autoPlacement } from '@floating-ui/dom'
import { watch } from "../../internal/watch";
import { classMap } from "lit/directives/class-map.js";

/**
 * @cssproperty [--odj-tooltip-background=#32383e] - The background of the tooltip
 * @cssproperty [--odj-tooltip-text-color=#ddd] - The text color of the tooltip
 * @cssproperty [--odj-tooltip-border-radius=5px] - The border radius of the tooltip
 * @cssproperty [--odj-tooltip-padding-vertical=9px] - The vertical padding of the tooltip
 * @cssproperty [--odj-tooltip-padding-horizontal=14px] - The horizontal padding of the tooltip
 * @cssproperty [--odj-tooltip-font-size=0.825rem] - The font size of the tooltip
 * 
 * 
 * @csspart tooltip-container - Container of the tooltip
 * @csspart tooltip-content - Content area of the tooltip
 */
@customElement('odj-tooltip')
export class OdjTooltip extends LitElement {

    static override styles = componentStyles


    /** The tooltip placement */
    @property()
    placement: 'top' | 'top-start' | 'top-end' | 'right' | 'right-start' | 'right-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end' | 'auto' = 'top' 

    /** Whether the tooltip is visible */
    @property({reflect: true, type: Boolean, attribute: 'open'})
    open = false

    /** The tooltip content */
    @property()
    content: string

    /** Disables mouse events and automatic flipping */
    @property({type: Boolean})
    static = false

    private target: HTMLElement

    @query('.tooltip-container')
    private tooltipContainer: HTMLDivElement

    @query('.tooltip-arrow')
    private tooltipArrow: HTMLDivElement

    private floatingUiCleanup: ReturnType<typeof autoUpdate>

    constructor() {
        super()
    }

    handleMouseEnter() {
        if (!this.static) {
            this.show()
        }
    }

    handleMouseLeave() {
        if (!this.static) {
            this.hide()
        }
    }

    handleFocus() {
        if (!this.static) {
            this.show()
        }
    }

    handleBlur() {
        if (!this.static) {
            this.hide()
        }
    }

    connectedCallback(): void {
        super.connectedCallback()

        this.addEventListener('mouseenter', this.handleMouseEnter)
        this.addEventListener('mouseleave', this.handleMouseLeave)
        this.addEventListener('focus', this.handleFocus, true)
        this.addEventListener('blur', this.handleBlur, true)
    }

    disconnectedCallback(): void {
        super.disconnectedCallback()

        this.removeEventListener('mouseenter', this.handleMouseEnter)
        this.removeEventListener('mouseleave', this.handleMouseLeave)
        this.removeEventListener('focus', this.handleFocus)
        this.removeEventListener('blur', this.handleBlur)
    }

    private handleTargetChange(e: Event) {
        this.target = this.getTarget()
        this.updatePosition()
    }

    /** Show the tooltip */
    public show() {
        this.open = true
    }

    /** Hide the tooltip */
    public hide() {
        this.open = false
    }


    private updatePosition() {

        if (!this.open || !this.target || !this.tooltipContainer) {
            return
        }


        computePosition(this.target, this.tooltipContainer, {
            placement: this.placement === 'auto' ? 'top' : this.placement,
            strategy: 'absolute',
            middleware: [
                this.placement === 'auto' ? autoPlacement() : (!this.static ? flip() : null),
                offset({mainAxis: 10}),
                arrow({element: this.tooltipArrow, padding: 10})
            ].filter(p => p !== null)
        }).then(({x, y, placement, middlewareData}) => {
            Object.assign(this.tooltipContainer.style, {
                left: `${x}px`,
                top: `${y}px`
            })

            const staticSide = {
                top: 'bottom',
                right: 'left',
                bottom: 'top',
                left: 'right',
              }[placement.split('-')[0]]

            const arrow = middlewareData.arrow
            Object.assign(this.tooltipArrow.style, {
                left: arrow.x !== null ? `${arrow.x}px` : '',
                top: arrow.y !== null ? `${arrow.y}px` : '',
                [staticSide]: '-4px',
            })
        })

    }

    @watch('content')
    @watch('placement')
    handleChanges() {
        this.updatePosition()
    }

    @watch('open', {waitUntilFirstUpdate: true})
    handleOpenChange() {
        if (this.open) {
            this.cleanupPositionUpdate()
            this.updatePosition()
            this.floatingUiCleanup = autoUpdate(this.target, this.tooltipContainer, this.updatePosition.bind(this))

        } else {
            this.cleanupPositionUpdate()
        }
    }

    private cleanupPositionUpdate() {

        if (this.floatingUiCleanup) {
            this.floatingUiCleanup()
            this.floatingUiCleanup = undefined
        }
    }

    private getTarget(): HTMLElement {
        const target = [...this.children].find(
            el => el.tagName.toLowerCase() !== 'style' && el.getAttribute('slot') !== 'content'
        )
        return target as HTMLElement
    }

    render() {

        const classes = {
            tooltip: true,
            'tooltip--shown': this.open,
        }

        return html`<div class="tooltip-target"><slot @slotchange="${this.handleTargetChange}"></slot></div><div class="tooltip-container" part="tooltip-container"><div class="${classMap(classes)}" role="tooltip" part="tooltip-content"><div class="tooltip-arrow" part="tooltip-arrow"></div><slot name="content">${this.content}</slot></div></div>`
    }

}

declare global {
    interface HTMLElementTagNameMap {
      "odj-tooltip": OdjTooltip;
    }
}