import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';
import {emitEvent} from '../../internal/event-dispatch';

/**
 * @dependency odj-align
 *
 * @slot - The headline caption.
 * @slot prefix - Optional prefix for the headline, this can be any element.
 * @slot right - Optional right aligned content for e.g. action buttons.
 * 
 * @cssproperty [--odj-section-headline-border-color=grey] - The border color of the overall headline.
 * @cssproperty [--odj-section-headline-highlight-border-color=white] - The border color of the content part of the headline.
 */
@customElement('odj-section-headline')
export class OdjSectionHeadline extends LitElement {
	static styles = css`:host{display:block;border-bottom:1px solid var(--odj-section-headline-border-color,grey);color:var(--odj-white)}.container{display:grid;grid-template-columns:max-content 1fr max-content;gap:10px}.container div,.container h2{display:flex;align-items:center;gap:.5rem;margin-top:0;margin-bottom:-1px;line-height:2rem;padding:.5rem 0}.heading{font-size:1.5rem;font-weight:400;border-bottom:2px solid var(--odj-section-headline-highlight-border-color,#fff)}`
	render() {
		return html`<div class="container"><h2 class="heading"><slot name="prefix"></slot><slot></slot></h2><div class="additional"><slot name="additional"></slot></div><div class="right"><slot name="right"></slot></div></div>`
	}
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-section-headline": OdjSectionHeadline;
    }
}