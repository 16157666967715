import { LitElement, PropertyValueMap, html } from "lit";
import { customElement, query } from "lit/decorators.js";
import { OdjToastContainer, createToast } from "../toast";

const expiryKey = 'odj_token_expires_at'

type TokenRefreshResponse = {
    token_expiry: string
    refreshed: boolean
}

@customElement('odj-token-refresh')
export class OdjTokenRefresh extends LitElement {

    @query('odj-toast-container')
    toastContainer: OdjToastContainer

    lastRefresh: number
    
    protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        this.scheduleTokenRefresh()
    }

    // Schedules a token refresh. We are very conservative here under which conditions we actually trigger a request
    // to the backend.
    scheduleTokenRefresh() {

        const url = new URL(window.location.href)
        const currentPath = url.toString().substring(url.origin.length)

        // Don't trigger refresh on landing page
        if (currentPath == '/' || currentPath == '' || currentPath.startsWith('/auth')) {
            return
        }

        const remainingTime = this.getRemainingTime()
        if (remainingTime === undefined) {
            return
        }
    
        // Schedule token refresh two minutes before the access token expires
        let refreshTimeout = (remainingTime) - 2*60*1000
        if (refreshTimeout < 0) {
            refreshTimeout = 0
        }

        // We add some jitter to avoid multiple requests at the same time if the user has multiple tabs open
        // Allow more jitter if the remaining time is more than two minutes
        // This will reduce the likelihood that we send two refresh requests
        // from two different tabs.
        const maxJitter = remainingTime < 2*60*1000 ? 3000 : 20000
        const jitter = Math.floor(Math.random() * maxJitter)

        setTimeout(async () => {

            // We are very conservative here and try to avoid to send more than one refresh every ten seconds
            if (this.lastRefresh !== 0 && Date.now() - this.lastRefresh < 10*1000) {
                console.warn('Refresh attempt to early, aborted.')
                return
            }

            // Before issuing a refresh request, we check the current expiration time again
            // since it's possible that another tab already performed the refresh.
            const remainingTime = this.getRemainingTime()

            // If more than three minutes remain of the token lifetime, just reschedule the token refresh (with new jitter)
            if (remainingTime > 3*60*1000) {
                this.scheduleTokenRefresh()
            }

            this.lastRefresh = Date.now()
            try {
                const resp = await fetch('/auth/refresh', {
                    method: 'POST',
                    credentials: 'include',
                })
    
                if (resp.ok) {
                    if (resp.status == 200) {
                        const body = (await resp.json() as TokenRefreshResponse)
                        window.localStorage.setItem(expiryKey, body.token_expiry)
                        if (body.refreshed) {
                            this.scheduleTokenRefresh()
                        } else {
                            console.warn('Token refresh disabled. Stored token expiry seems to be earlier than the real value.')
                        }
                    }
                } else {
                    createToast(this.toastContainer, 'Token refresh failed.', 'danger', 0, 'Manually refresh', `/authenticate?redirect_uri=${encodeURIComponent(currentPath)}`)
                }
            } catch(e) {
                createToast(this.toastContainer, 'Token refresh failed.', 'danger', 0, 'Manually refresh', `/authenticate?redirect_uri=${encodeURIComponent(currentPath)}`)
            }


        }, refreshTimeout+jitter)
    }

    getRemainingTime(): number | undefined {
        const tokenExpiry = window.localStorage.getItem(expiryKey)
            
        // If we don't have a token expiration date, just do nothing
        if (!tokenExpiry || tokenExpiry === 'undefined') {
            return undefined
        }

        const expiryDate = new Date(tokenExpiry)
        // @ts-ignore
        return (expiryDate - Date.now())
    }

    render() {
        return html`<odj-toast-container></odj-toast-container>`
    }

}