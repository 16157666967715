import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';

/**
 * @slot - Elements on the left side.
 * @slot middle - Elements in the center.
 * @slot right - Elements on the right side.
 */
@customElement('odj-align')
export class OdjAlign extends LitElement {
	static styles = css`:host{display:grid;grid-template-columns:auto 1fr auto}div{display:flex;flex-direction:row;align-items:center}.middle{justify-content:center}`
	render() {
		return html`<div class="left"><slot></slot></div><div class="middle"><slot name="middle"></slot></div><div class="right"><slot name="right"></slot></div>`
	}
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-align": OdjAlign;
    }
}