import {LitElement, html, css} from 'lit';
import {customElement, query} from 'lit/decorators.js';

/**
 * @slot - Reserved for slotting tab elements.
 *
 * @cssproperty [--border-color-hover=rgba(255, 255, 255, 1)] - The color of the border on the active element.
 */
@customElement('odj-tab-nav')
export class OdjTabNav extends LitElement {
	static styles = css`:host{display:flex;flex-wrap:wrap;gap:1.5rem;border-bottom:1px solid var(--odj-black-light)}`

	@query('slot')
	items: HTMLSlotElement;

	get active() {
		return this.items.assignedElements().filter(ele => ele.hasAttribute('active'))[0];
	}

	firstUpdated() {
		this.items.addEventListener('slotchange', e => {
			this.items.assignedElements().forEach(ele => {
				ele.addEventListener('click', e => {
					const curr = this.active
					if (curr) {
						curr.removeAttribute('active');
					} 

					ele.setAttribute('active', '');
				});
			});
		});
	}

	render() {
		return html`<slot></slot>`
	}
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-tab-nav": OdjTabNav;
    }
}