import {LitElement, html, css} from 'lit';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {customElement, property} from 'lit/decorators.js';

/**
 * @prop {string} href - Target for if the tab should work like an anchor link.
 */
@customElement('odj-tab')
export class OdjTab extends LitElement {
	static styles = css`:host{display:inline-block;cursor:default;--color:var(--odj-white-dark);user-select:none;-webkit-user-select:none;background-color:transparent;padding-bottom:0;margin-bottom:-1px}:host([active]),:host([selected]){--color:white}::slotted(odj-icon){vertical-align:middle}a,span{text-decoration:none;color:var(--color);font-size:1rem;line-height:1.625;padding-bottom:.25rem;border-bottom:2px solid transparent;display:block}:host([active]) :is(span,a),:host([selected]) :is(span,a),:is(span,a):hover{border-bottom:2px solid var(--odj-tab-border-color-hover,#fff)}.active-placeholder{display:block;font-weight:700;height:0;overflow:hidden;visibility:hidden}odj-badge{margin-left:.5rem}`

	@property({attribute:true, reflect:true, type:String})
	href: string;

    @property()
    badge?: string

    @property({type: Boolean})
    selected = false

    @property({type: Boolean})
    active = false

    private handleSlotChange() {
        this.requestUpdate()
    }

	render() {
        // .active-placeholder duplicates the tab content but with a bold font
        const tabContent = html`<span class="active-placeholder" aria-hidden="true">${unsafeHTML(this.innerHTML)}</span><slot @slotchange="${this.handleSlotChange}"></slot>${this.badge ? html`<odj-badge pill variant="${this.active || this.selected ? 'active' : 'inactive'}">${this.badge}</odj-badge>` : ''}`

		if (this.href) {
			return html`<a href="${this.href}">${tabContent}</a>`;
		}
		return html`<span>${tabContent}</span>`;
	}
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-tab": OdjTab;
    }
}