import {LitElement, html, css} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { emitEvent } from '../../internal/event-dispatch';

/**
 * Loads the response of the provided URL into a container.
 * 
 * @event {{type: 'NETWORK_ERROR' | 'HTTP_ERROR', code: number, reason: string}} odj-widget-error - Dispatched if loading the target URL fails. Allows for custom error handling
 * 
 * @slot - Passed along content (to show a default).
 * @slot loader - Slot to define a loading state/screen. (Defaults to a centered simple `odj-spinner`)
 * @slot error - Slot to define an error state/screen.
 */
@customElement('odj-widget')
export class OdjWidget extends LitElement {
	
    /** The target URL to be loaded. */
    @property({attribute: true, reflect: true})
	href: string;


    /** Label shown below the loading spinner while the widget is loading. */
    @property({reflect: true, attribute: 'loading-label', type: String})
    loadingLabel?: string

    @property({attribute: 'accept', type: String})
    accept?: string

    /** Determines if the widget load resultet in an error. */
	@state()
	private hasError = false

    /** Whether the widget is still loading  */
    @state()
	private isLoading = false

    @state()
    error: string

	static override styles = css`:host{display:block}slot[name]::slotted(*){display:none}slot[name=loader]{display:block;text-align:center}slot[name=loader]::slotted(*),slot[name=loader]>*{display:none}.has-error slot[name=error]::slotted(*){display:inherit}.is-loading slot[name=loader]::slotted(*),.is-loading slot[name=loader]>*{display:inherit}.loading-container{text-align:center}.loading-label{display:block}.error-icon{color:var(--odj-error-color);font-size:1.5rem;vertical-align:middle;margin-right:.5rem}`

	connectedCallback() {
		super.connectedCallback()
		this.load();
	}

	load() {
		this.isLoading = true

        const options: RequestInit = {
            credentials: 'include'
        }

        if (this.accept) {
            options.headers = {
                'Accept': this.accept
            }
        }


		fetch(this.href, options).then(async resp => {
            this.isLoading = false;
            if (!resp.ok) {
                this.error = `Error loading widget (status code: ${resp.status})`
                this.hasError = true
                emitEvent(this, 'odj-widget-error', {
                    detail: {
                        type: 'HTTP_ERROR',
                        code: resp.status,
                        reason: resp.statusText,
                    }
                })
                return
            }

            const html = await resp.text()

			this.append(document.createRange().createContextualFragment(html));

        }).catch(err => {
			this.isLoading = false;
			this.hasError = true;
            this.error = `Network failure: ${err}`
			console.error(err);
            emitEvent(this, 'odj-widget-error', {
                detail: {
                    type: 'NETWORK_ERROR',
                    code: -1,
                    reason: err,
                }
            })
		});
	}

	render() {

        const wrapperClasses = {
            'has-error': this.hasError,
            'is-loading': this.isLoading
        }

		return html`<div class="${classMap(wrapperClasses)}"><slot name="loader"><div class="loading-container"><odj-spinner></odj-spinner><span class="loading-label">${this.loadingLabel}</span></div></slot><slot name="error">${this.hasError ? html`<odj-icon icon="warning" class="error-icon"></odj-icon>${this.error}`: ''}</slot><slot></slot></div>`
	}
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-widget": OdjWidget;
    }
}