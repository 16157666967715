import { html, LitElement, TemplateResult } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { live } from "lit/directives/live.js";
import { emitEvent } from "../../internal/event-dispatch";
import { FormSubmitController } from "../../utils/form-control";
import { getElementId } from "../../internal/id-generator";
import { HasSlotController } from "../../internal/slot";
import { OdjTooltip } from "../tooltip";
import { componentStyles } from "./checkbox.styles";


/**
 * @element odj-checkbox
 * 
 * @event odj-change - Fires when the checkbox state is changed by the user
 * 
 * @slot help-text - Allows formatting the help text
 * @slot label - Allows formatting the label
 */
@customElement('odj-checkbox')
export class OdjCheckbox extends LitElement {

    static override styles = componentStyles

    /** Name of the checkbox for form submission */
    @property({ reflect: true })
    name: string | undefined

    /** Value of the checkbox */
    @property({ reflect: true })
    value = 'on'
    
    /** Label for the checkbox */
    @property()
    label: string

    @property()
    title: string

    /** The help text */
    @property({attribute: 'help-text'})
    helpText: string

    /** How to render the checkbox */
    @property()
    variant: 'checkbox' | 'switch' = 'checkbox'

    /** Checks the checkbox */
    @property({ reflect: false, type: Boolean})
    checked = false

    /** Disables the checkbox. */
    @property({ type: Boolean, reflect: true })
    disabled = false

    /** Puts the checkbox in readonly mode. */
    @property({ type: Boolean, reflect: true })
    readonly = false
    
    /** Makes the checkbox a required field. */
    @property({ type: Boolean, reflect: true })
    required = false

    /** This will be true when the control is in an invalid state. Validity is determined by the `required` prop. */
    @property({ type: Boolean, reflect: true }) 
    invalid = false

    @property({ type: Boolean })
    standalone = false

    @property({ type: Boolean, attribute: 'hide-label'})
    hideLabel = false

    @query('input')
    private input: HTMLInputElement

    @query('odj-tooltip')
    private tooltip: OdjTooltip

    @state()
    private hasFocus = false

    @state()
    private isTouched = false

    @state()
    private hasReportedValidity = false

    @state()
    private inputId: string

    @state()
    private labelId: string

    private formController: FormSubmitController
    private hasSlotController: HasSlotController

    get hasValidation(): boolean {
        return this.required
    }

    constructor() {
        super()
        this.inputId = `checkbox-${getElementId()}`
        this.labelId = `label-${getElementId()}`
        this.hasSlotController = new HasSlotController(this, 'label', 'help-text')
        this.formController = new FormSubmitController(this, {
            value: (control: OdjCheckbox) => control.checked ? control.value : undefined
        })

        this.addEventListener('focus', () => this.focus())
    }

    /** Resets the checkbox to its initial state and removes any validation state */
    reset() {
        this.checked = this.hasAttribute('checked')
        this.invalid = false
        this.hasReportedValidity = false
    }

    private handleClick(e: Event) {
        e.stopPropagation()
        if (!this.disabled && !this.readonly) {
            this.checked = !this.checked

            if (this.checked) {
                this.invalid = false
            }

            emitEvent(this, 'odj-change')
        }
    }

    private handleFocus() {
        this.hasFocus = true

        // If the element has keyboard focus, show the help tooltip
        if (this.input.matches(':focus-visible')) {
            this.tooltip?.show()
        }
    }

    private handleBlur() {
        this.hasFocus = false
        this.isTouched = true
        this.tooltip?.hide()
    }

    private handleChange() {
        this.isTouched = true
        this.setValidity()
        // If the user has submitted the form already and the validation has failed for this checkbox,
        // we want to give immediate feedback once the issue is fixed
        if (this.hasReportedValidity) {
            this.reportValidity()
        }
    }

    /** Focus the checkbox */
    focus(options?: FocusOptions): void {
        this.input.focus(options)
    }

    /** Report validatity */
    reportValidity() {
        this.hasReportedValidity = true
        this.setValidity()
        return this.input.reportValidity()
    }

    setValidity() {
        this.invalid = this.required && !this.checked
    }

    /** Set a custom validation error message */
    setCustomValidity(error: string) {
        return this.input.setCustomValidity(error)
    }

    protected renderControl(): TemplateResult {
        const checkmark = this.variant === 'checkbox' && this.checked ? html`<odj-icon icon="check-small"></odj-icon>` : ''
        const switcher = this.variant === 'switch' ? html`<span class="switcher"></span>` : ''

        return html`<input type="checkbox" .id="${this.inputId}" .value="${live(this.value)}" title="${ifDefined(this.title || (this.hideLabel ? this.label : undefined))}" ?checked="${this.checked}" ?disabled="${this.disabled || this.readonly}" ?required="${this.required}" name="${ifDefined(this.name)}" aria-labelledby="${this.labelId}" aria-checked="${this.checked ? 'true' : 'false'}" .tabindex="${this.hasAttribute('tabindex') ? (Number(this.getAttribute('tabindex'))) : 0}" @click="${this.handleClick}" @blur="${this.handleBlur}" @focus="${this.handleFocus}" @change="${this.handleChange}"> <span class="control-wrapper"><span class="control">${checkmark}</span></span>`
    }

    render() {
        
        const showValidationState = this.hasReportedValidity || this.isTouched

        const classes = {
            'odj-checkbox': this.variant === 'checkbox',
            'odj-switch': this.variant === 'switch',
            'odj-checkbox--checked': this.checked,
            'odj-checkbox--focused': this.hasFocus,
            'odj-checkbox--required': this.required,
            'odj-checkbox--valid': showValidationState && !this.invalid,
            'odj-checkbox--invalid': (showValidationState || this.hasAttribute('invalid')) && this.invalid,
            'odj-checkbox--disabled': this.disabled,
            'odj-checkbox--readonly': this.readonly,
        }
        
        const showErrorLabel = showValidationState && this.invalid
        let statusIndicator = html``
        
        // This should be set upon the first form submit
        if (this.hasValidation && this.invalid) {
            statusIndicator = html`<odj-icon icon="x-small" part="status-indicator-icon"></odj-icon>`
        }

        const control = this.renderControl()

        const hasHelpText = this.hasSlotController.test('help-text') || !!this.helpText

        const formFieldClasses = {
            'form-field': true,
            'form-field--disabled': this.readonly || this.disabled,
            'form-field--has-help-text': hasHelpText,
            'form-field--hidden-label': this.hideLabel,
        }

        return html`<div class="${classMap(formFieldClasses)}"><label .id="${this.labelId}" class="${classMap(classes)}">${control} ${!this.hideLabel ? html`<slot name="label">${this.label || ''}</slot>` : ''}<odj-tooltip placement="right"><span slot="content"><slot name="help-text">${this.helpText}</slot></span><odj-icon icon="circle-help" class="help-text-icon"></odj-icon></odj-tooltip></label> ${this.standalone ? `` : html`<span class="form-field-status">${statusIndicator}</span>`
                } ${showErrorLabel ? html`<div class="error-text">This checkbox is mandatory.</div>` : ''}</div>`
    }

}


declare global {
    interface HTMLElementTagNameMap {
      "odj-checkbox": OdjCheckbox;
    }
}