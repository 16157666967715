import { css, html, LitElement, TemplateResult } from "lit";
import { customElement } from "lit/decorators.js";

/**
 * Provides default styles for toasts.
 * 
 * @element odj-toast-container
 */
@customElement('odj-toast-container')
export class OdjToastContainer extends LitElement {

    static override styles = css`:host{display:block;position:fixed;left:50%;bottom:20px;display:block;transform:translateX(-50%);margin:0 auto;width:576px;z-index:1050}odj-toast{max-width:578px}`

    render(): TemplateResult {
        return html`<slot></slot>`
    }
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-toast-container": OdjToastContainer;
    }
}