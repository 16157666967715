import { html, LitElement } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { FormSubmitController } from "../../utils/form-control";
import { OdjInput } from "../input";
import { componentStyles } from "./tag-input.styles";

@customElement('odj-tag-input')
export class OdjTagInput extends LitElement {

    static override styles = componentStyles

    @property()
    name: string | undefined

    @property()
    label: string | undefined

    @property({ reflect: true, attribute: 'btn-label', type: String })
    btnLabel: string | undefined

    /** Help text to assist the user filling out the field */
    @property({ reflect: true, attribute: 'help-text', type: String })
    helpText: string

    /** Whether the tag input is disabled */
    @property({ reflect: true, type: Boolean})
    disabled = false

    /** List of tags */
    @property({ reflect: false, attribute: false })
    tags: string[] = []

    @query('odj-input')
    input: OdjInput


    formController: FormSubmitController

    /** Allows setting the value of the tag input via a comma separated list of values.  */
    @property({ reflect: false, type: String })
    set value(val: string) {
        if (val.trim().length > 0) {
            this.tags = val.split(',').map(t => t.trim())
        }
    }

    get value(): string {
        return this.tags.join(',')
    }

    constructor() {
        super()

        this.formController = new FormSubmitController(this)
    }

    /** Resets the tag input to its initial state */
    reset() {
        this.value = this.getAttribute('value') || ''
        this.input.reset()
    }

    private removeTag(idx: number) {
        this.tags = this.tags.filter((_, i) => i !== idx)
    }

    private handleKeyup(e: CustomEvent) {
        if (['Enter', ','].includes(e.detail.key)) {
            this.addCurrentTag();
        }
    }

    private addCurrentTag() {
        let tag = this.input.value
        this.input.value = ''
        tag = tag.replace(/,+$/, '').trim()

        if (tag.length > 0) {
            this.tags = [...this.tags, tag]

        }
    }

    private handleTagKeydown(e: KeyboardEvent, idx: number) {
        if (this.disabled) {
            return
        }
        if (e.key === 'Backspace' || e.key === 'Delete') {
            this.removeTag(idx)
        }
    }

    render() {
        return html`<div class="input-wrapper"><odj-input label="${this.label}" help-text="${this.helpText}" @odj-keyup="${this.handleKeyup}" standalone ?disabled="${this.disabled}"><odj-button slot="postfix" variant="primary" ?disabled="${this.disabled}" @click="${this.addCurrentTag}">${this.btnLabel || 'Add'}</odj-button></odj-input></div><div class="tags">${this.tags.map((tag, i) => !this.disabled ? html`<odj-label tabindex="0" @keydown="${(e: KeyboardEvent) => this.handleTagKeydown(e, i)}" action-icon="x-small" @odj-label-action="${() => this.removeTag(i)}">${tag}</odj-label>` : html`<odj-label>${tag}</odj-label>`)}</div>`
    }
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-tag-input": OdjTagInput;
    }
}