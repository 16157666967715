import {LitElement, html, css} from 'lit';
import {customElement, query} from 'lit/decorators.js';
import {emitEvent} from '../../internal/event-dispatch';

/**
 * @dependency odj-align
 * @dependency odj-icon
 *
 * @slot option - Reserved for option [elements] to be cycled through.
 *
 * @fires {{value: string}} odj-change - On every change (i.e. previous/next element button click) dispatched event which contains the visible elements' `select-value` or `value` attribute as value.
 */
@customElement('odj-carousel-select')
export class OdjCarouselSelect extends LitElement {
	static styles = css`:host{display:flex}slot::slotted(*){display:none}slot::slotted([visible=""]){display:inherit}nav{box-sizing:border-box;padding:0 1rem}odj-button::part(button){display:flex;flex-direction:column;justify-content:center}odj-icon{vertical-align:middle;font-size:1rem;margin-top:0;top:0}button:hover{opacity:1}`

	@query('slot[name="option"]')
	private optionSlot: HTMLSlotElement;

	private get currentSelection() {
		return this.optionSlot.assignedElements().filter(ele => ele.hasAttribute('visible'))[0];
	}

	private get firstOption() {
		return this.optionSlot.assignedElements()[0];
	}

	private get lastOption() {
		return this.optionSlot.assignedElements()[this.optionSlot.assignedElements().length - 1];
	}

	private get nextOption() {
		if (this.currentSelection) {
			if (this.currentSelection.nextElementSibling) {
				return this.currentSelection.nextElementSibling;
			} else {
				return this.firstOption;
			}
		} else {
			return this.firstOption;
		}
	}

	private get previousOption() {
		if (this.currentSelection) {
			if (this.currentSelection.previousElementSibling) {
				return this.currentSelection.previousElementSibling;
			} else {
				return this.lastOption;
			}
		} else {
			return this.lastOption;
		}
	}
	
    private get numOptions(): number {
        return this.optionSlot?.assignedElements().length
    }

    handlePrev() {
        const prev = this.previousOption;
        const curr = this.currentSelection;

        if (prev) {
            prev.setAttribute('visible', '');
            emitEvent(this, 'odj-change', {
                detail: {
                    value: prev.getAttribute('select-value') || prev.getAttribute('value')
                }
            });
        }

        if (curr !== prev) {
            curr.removeAttribute('visible');
        }
    }

    handleNext() {
        const next = this.nextOption;
        const curr = this.currentSelection;

        if (next) {
            next.setAttribute('visible', '');
            emitEvent(this, 'odj-change', {
                detail: {
                    value: next.getAttribute('select-value') || next.getAttribute('value')         
                }					
            });
        }

        if (curr !== next) {
            curr.removeAttribute('visible');
        }
    }

	render() {
		return html`<odj-align><slot name="option"></slot><nav><odj-button class="previous" type="button" variant="icon" @click="${this.handlePrev}"><odj-icon icon="chevron-left-small"></odj-icon></odj-button><odj-button class="next" type="button" variant="icon" @click="${this.handleNext}"><odj-icon icon="chevron-right-small"></odj-icon></odj-button></nav></odj-align>`
	}
}


declare global {
    interface HTMLElementTagNameMap {
      "odj-carousel-select": OdjCarouselSelect;
    }
}