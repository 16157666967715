import { LitElement, css, html, svg } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { unsafeSVG } from 'lit/directives/unsafe-svg.js'
import { getBasePath } from '../../utils/base-path';
import { watch } from '../../internal/watch';
import { fetchIcon } from './request'
import { classMap } from 'lit/directives/class-map';

const parser = new DOMParser()

/**
 * This can be used to display icons from the ODJ icon lib.
 * 
 * @element odj-icon
 * 
 * @prop {string} icon - The icon that should be displayed.
 */
@customElement('odj-icon')
export class OdjIcon extends LitElement {

    static override styles = css`:host{display:inline-block;width:1em;height:1em;contain:strict;box-sizing:content-box!important;--background:transparent;background:var(--background)}:host([rounded]){border-radius:50%;padding:.25rem}svg{display:block;height:100%;width:100%}`

    @property({ attribute: 'icon', reflect: true, type: String })
    icon: string | undefined

    @state()
    private url = ''

    @state()
    private svg = ''
    
    @watch('icon')
    async handleIconChange() {

        if (this.icon === undefined || this.icon === '') {
            this.svg = ''
            return
        }
        
        const url = `${getBasePath()}/assets/icons/${this.icon}.svg`
        this.url = url
        
        const icon = await fetchIcon(this.url)

        // Make sure the URL does not change while the icon is fetched
        if (this.url !== url) {
            return;
        }

        this.svg = ''
        
        if (icon.ok) {
            const doc = parser.parseFromString(icon.svg, 'text/html')
            const svgElement = doc.body.querySelector('svg')

            // Make the icon styleable from the outside
            svgElement.setAttribute('part', 'icon')

            if (svgElement !== null) {
                this.svg = svgElement.outerHTML
            }
        }
    }

    override render() {
        return html`${unsafeSVG(this.svg)}`
    }
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-icon": OdjIcon;
    }
}