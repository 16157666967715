import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";



/**
 * Renders a badge.
 */
@customElement('odj-badge')
export class OdjBadge extends LitElement {
    
    static override styles = css`:host{box-sizing:border-box;display:inline-flex;justify-content:center;align-content:center;align-items:center;text-align:center;font-size:.75rem;line-height:20px;font-weight:700;padding:.25rem .5rem;color:var(--odj-badge-text-color);background:var(--odj-badge-default)}:host([variant=success]){background:var(--odj-badge-success)}:host([variant=info]){background:var(--odj-badge-info)}:host([variant=danger]){background:var(--odj-badge-danger)}:host([variant=warning]){background:var(--odj-badge-warning)}:host([variant=active]){background:var(--odj-badge-active)}:host([variant=inactive]){background:var(--odj-badge-inactive)}:host([pill]){border-radius:.875rem;min-width:1.75rem;height:1.75rem;font-size:13px;line-height:1}`

    /** Variant of the badge */
    @property()
    variant: 'default' | 'info' | 'warning' | 'danger' | 'active' | 'inactive' = 'default'

    @property({type: Boolean})
    pill = false

    render() {

        return html`<slot></slot>`

    }

}


declare global {
    interface HTMLElementTagNameMap {
      "odj-badge": OdjBadge;
    }
}