import { html, LitElement, PropertyValues, TemplateResult } from "lit";
import { customElement, property, query, queryAssignedElements, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from "lit/directives/live.js";
import { emitEvent } from "../../internal/event-dispatch";
import { FormSubmitController } from "../../utils/form-control";
import { renderFormWrapper } from "../../internal/form-wrapper";
import { getElementId } from "../../internal/id-generator";
import { HasSlotController } from "../../internal/slot";
import { getSyncValidator, getAsyncValidator, getErrorMessage, RegistryEntry } from "../../utils";
import { createToast, OdjToastContainer } from "../toast";
import { componentStyles } from "./input.styles";

/**
 * @element odj-input
 * 
 * @event odj-input - The `input` event of the underlying input
 * @event {{ key: string }} odj-keydown - The `keydown` event of the underlying input
 * @event {{ key: string }} odj-keyup - The `keyup` event of the underlying input
 * @event odj-change - The `change` event of the underlying input 
 * @event odj-blur - The `blur` event of the underlying input
 * @event odj-focus - The `focus` event of the underlying input
 * 
 * @slot label - Allows formatting of the label
 * @slot help-text - Allows formatting of the help text
 * @slot datalist - Allows adding a <datalist> to the input
 */
@customElement('odj-input')
export class OdjInput extends LitElement {

    static override styles = componentStyles

    /** Name of the represented field in form submissions */
    @property({ reflect: true })
    name: string | undefined

    /** Label for the field */
    @property({ reflect: true })
    label: string | undefined

    /** Value of the form field */
    @property({ reflect: false, attribute: 'value'})
    value: string | undefined = ''

    /** Type of the form field */
    @property({ reflect: true })
    type: 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'date' | 'datetime-local' | 'time' = 'text'

    /** Placeholder text */
    @property({ reflect: true })
    placeholder: string

    /** Help text to assist the user filling out the field */
    @property({ reflect: true, attribute: 'help-text', type: String })
    helpText: string

    /** Whether the field is required for form submission */
    @property({ reflect: true, type: Boolean })
    required: boolean | undefined

    /** Pattern that the user input must match */
    @property({ reflect: true, type: String })
    pattern: string | undefined

    /** Whether the current field is invalid */
    @property({ type: Boolean, reflect: false })
    invalid = false

    /** Pre-fills the error message of the form field */
    @property({reflect: false, attribute: 'error-text', type: String})
    errorText: string

    /** Disables the input. */
    @property({ type: Boolean, reflect: true })
    disabled = false

    /** Makes the input readonly. */
    @property({ type: Boolean, reflect: true })
    readonly = false

    /** The maximum length of input that will be considered valid. */
    @property({ type: Number })
    maxlength: number

    /** The minimum length of input that will be considered valid. */
    @property({ type: Number })
    minlength: number
    
    /** The input's minimum value. */
    @property()
    min: number | string

    /** The input's maximum value. */
    @property()
    max: number | string;

    /** The input's step attribute. */
    @property({type: Number})
    step: number

    /** The inputmode (useful to enable specialized keyboards on mobile devices) */
    @property()
    inputmode: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url'

    /** Renders at full width of the parent container and without validation */
    @property({ type: Boolean, reflect: true, attribute: 'standalone' })
    standalone = false

    /** Set a comma-separated list of validators */
    @property({
        reflect: false, attribute: 'validators', converter: {
            fromAttribute: (value, type) => {
                return value.split(',').map(validatorKey => validatorKey.trim())
            },
            toAttribute: (value, type) => (value as string[]).join(',')
        }
    })
    validators: (string | RegistryEntry)[] = []

    /** Adds a "Copy" button to the input */
    @property({type: Boolean})
    copyable: boolean

    @query('input')
    input: HTMLInputElement

    @query('datalist')
    private datalist: HTMLDataListElement

    @query('odj-toast-container')
    private toastContainer: OdjToastContainer

    @queryAssignedElements({selector: 'datalist', slot: 'datalist'})
    private slottedDataLists: HTMLDataListElement[]

    @state()
    private hasDataList = false

    @state()
    private isTouched = false

    @state()
    private hasReportedValidity = false

    @state()
    private validationPending = false

    @state()
    private inputId: string

    @state()
    private labelId: string

    @state()
    private showPassword = false

    get hasValidation(): boolean {
        return !this.standalone && (
            this.required
            || this.min !== undefined
            || this.max !== undefined
            || this.maxlength !== undefined
            || this.minlength !== undefined
            || this.pattern !== undefined
            || this.type === 'email'
            || this.validators.length > 0
            || this.hasAttribute('invalid'))
    }

    get hasAsyncValidation(): boolean {
        return this.validators.map(v => getAsyncValidator(v)).filter(v => v !== null).length > 0
    }   

    get valueAsNumber() {
        return this.input?.valueAsNumber ?? null
    }

    get valueAsDate() {
        return this.input?.valueAsDate ?? null
    }

    set valueAsDate(newValue: Date | null) {
        this.updateComplete.then(() => {
            this.input.valueAsDate = newValue
            this.value = this.input.value
        })
    }


    private formController: FormSubmitController
    private hasSlotController: HasSlotController
    private mutationObserver: MutationObserver

    constructor() {
        super()
        this.formController = new FormSubmitController(this)
        this.hasSlotController = new HasSlotController(this, 'label', 'help-text', 'prefix', 'postfix')

        this.inputId = 'odj-input-' + getElementId()
        this.labelId = 'label' + getElementId()

        this.mutationObserver = new MutationObserver((mutations) => {
            this.handleDataListChange()
        })

    }

    disconnectedCallback(): void {
        super.disconnectedCallback()
        this.mutationObserver?.disconnect()
    }

    /** Resets the input to its initial state and removes any validation state */
    reset() {
        this.value = this.getAttribute('value') || ''
        this.invalid = false
        this.hasReportedValidity = false
        this.isTouched = false
        this.validationPending = false
        this.errorText = ''
    }

    /** Focus the input */
    focus(options?: FocusOptions): void {
        this.input.focus(options)
    }

    /** Report input validity based only on synchronuous validators */
    reportValidity(): boolean {
        this.hasReportedValidity = true
        const customValidity = this.updateSyncValidity()
        return customValidity && this.input.reportValidity();
    }

    /** Report input validity and wait for asynchronous validators */
    async waitForValidity(): Promise<boolean> {
        this.hasReportedValidity = true
        const customValidity = await this.updateValidity()
        return customValidity && this.input.reportValidity();
    }

    private updateBuiltInErrors(): boolean {
        let builtInInvalid = false

        // First check build-in validators except customError
        const builtInErrors: (keyof ValidityState)[] = ['badInput', 'patternMismatch', 'rangeOverflow', 'rangeUnderflow', 'tooLong', 'tooShort', 'typeMismatch', 'valueMissing', 'stepMismatch']

        for (const errorType of builtInErrors) {
            if (this.input.validity[errorType]) {

                this.errorText = getErrorMessage(errorType, this.input)
                this.invalid = true
                builtInInvalid = true
                break
            }
        }

        // If there is a custom error set on the form field, don't update the invalid state and error text
        if (!builtInInvalid && !this.input.validity.customError) {
            this.errorText = ''
            this.invalid = false
        }

        return builtInInvalid
    }

    // Runs all custom validators (sync + async)
    private async updateValidity(): Promise<boolean> {
        const syncValid = this.updateSyncValidity()
        return this.updateAsyncValidity(!syncValid)
    }

    // Runs all synchronuous validators, including the built-in validation
    private updateSyncValidity(): boolean {
        let syncInvalid = this.updateBuiltInErrors()


        for (let v of this.validators) {
            const syncValidator = getSyncValidator(v)  
            // Easy case: validator is synchronous
            if (syncValidator !== null) {
                const result = syncValidator(this.value, this)

                let isValid = result.isValid
                if (!isValid) {
                    this.invalid = true
                    syncInvalid = true
                    this.errorText = result.message
                }
                continue
            }
        }

        return !syncInvalid

    }

    // Runs the async validators
    // This function requires the result of the sync validation in order
    // to not override its results.
    private async updateAsyncValidity(syncInvalid: boolean) {
        const promises = []

        for (let v of this.validators) {
            // We collect the promises of asynchronous validators and await them all at once later
            const asyncValidator = getAsyncValidator(v)

            if (asyncValidator !== null) {
                const result = asyncValidator(this.value, this)
                promises.push(result)
            }
        }

        // If there are any AsyncValidators, we put the component into a "validation pending" state and wait for the result
        if (promises.length > 0) {
            this.validationPending = true
            const validationStates = await Promise.all(promises)

            const failedValidation = validationStates.find((result) => !result.isValid)
            // A valid async validator should not override the synchronous validators
            this.invalid = syncInvalid || failedValidation !== undefined
            if (failedValidation !== undefined) {
                this.errorText = failedValidation.message
                this.input.setCustomValidity(failedValidation.message)
            } else {
                if (!this.invalid) {
                    this.errorText = ''
                }
                this.input.setCustomValidity('')
            }
            this.validationPending = false
            return !this.invalid
        } else {
            return !syncInvalid   
        }
        
    }

    private setCustomValidity(error: string) {
        this.invalid = error !== ''
        this.errorText = error
        return this.input.setCustomValidity(error)
    }


    private handleClick(e: Event) {
        if (!e.defaultPrevented) {
            this.focus({ preventScroll: true })
        }
    }

    private handleInvalid(e: Event) {
        this.invalid = true
        this.updateBuiltInErrors()
        e.preventDefault()
    }

    private handleBlur(e: Event) {
        this.isTouched = true
        emitEvent(this, 'odj-blur')
        this.updateValidity()
    }

    private handleFocus(e: Event) {
        emitEvent(this, 'odj-focus')
    }

    private handleInput(e: Event) {
        this.value = this.input.value
        // If this field has been validated before, run all synchronous validators on every input
        // to give the user quick feedback.
        if (this.isTouched || this.hasReportedValidity) {
            this.input.setCustomValidity('')
            this.updateSyncValidity()
        }
        emitEvent(this, 'odj-input')
    }

    private handleChange(e: Event) {
        this.value = this.input.value
        emitEvent(this, 'odj-change')
    }

    private async handleKeydown(e: KeyboardEvent) {
        const customKeydown = emitEvent(this, 'odj-keydown', {
            cancelable: true,
            detail: { key: e.key }
        })

        if (e.key === 'Enter') {
            await this.updateValidity()
            if (!customKeydown.defaultPrevented) {
                this.formController.submit()
            }
        }
    }

    private handleKeyup(e: KeyboardEvent) {
        emitEvent(this, 'odj-keyup', { detail: { key: e.key } })
    }

    private async handleCopy(e: PointerEvent) {
        await navigator.clipboard.writeText(this.value)
        createToast(this.toastContainer, 'Copied to clipboard.', 'success', 3000)
    }

    private handleDataListChange() {
        if (this.slottedDataLists.length > 0) {
            this.datalist?.remove()
            const list = this.slottedDataLists[0].cloneNode(true) as HTMLDataListElement
            list.id = 'datalist'
            this.hasDataList = true
            this.renderRoot.append(list)
        } else {
            this.hasDataList = false
            if (this.datalist) {
                this.datalist.remove()
            }
        }
    }

    protected firstUpdated(_changedProperties: PropertyValues<this>): void {
        if (this.errorText !== '') {
            this.setCustomValidity(this.errorText || '')
        }

        this.handleDataListChange()

        this.mutationObserver.observe(this, {
            subtree: true,
            childList: true,
        })
    }


    renderControl(showValidationResult: boolean): TemplateResult {
        const classes = {
            'odj-input': true,
            'odj-input--valid': showValidationResult && !this.validationPending && !this.invalid,
            'odj-input--invalid': showValidationResult && !this.validationPending && this.invalid,
            'odj-input--disabled': this.disabled,
            'odj-input--readonly': this.readonly,
            'odj-input--standalone': this.standalone,
            'odj-input--has-prefix': this.hasSlotController.test('prefix'),
            'odj-input--has-postfix': this.hasSlotController.test('postfix') || this.copyable,
        }

        
        // For all fields which are not password, we pass through the type. If the field is password, we
        // only set the type to "password" if showPassword is false.
        const type = this.type !== 'password' || !this.showPassword ? this.type : 'text'
        const passwordIcon = this.showPassword ? 'eye-disabled' : 'eye'
        
        return html`<div class="${classMap(classes)}" part="control-container" @click="${this.handleClick}"><div class="odj-input__grid"><slot name="prefix"></slot><input type="${type}" name="${this.name}" id="${this.inputId}" class="odj-input__field" .value="${live(this.value)}" ?required="${this.required}" ?disabled="${this.disabled}" ?readonly="${this.readonly}" step="${ifDefined(this.step)}" inputmode="${ifDefined(this.inputmode)}" placeholder="${ifDefined(this.placeholder)}" pattern="${ifDefined(this.pattern)}" min="${ifDefined(this.min)}" max="${ifDefined(this.max)}" minlength="${ifDefined(this.minlength)}" maxlength="${ifDefined(this.maxlength)}" @invalid="${this.handleInvalid}" @focus="${this.handleFocus}" @blur="${this.handleBlur}" @input="${this.handleInput}" @change="${this.handleChange}" @keydown="${this.handleKeydown}" @keyup="${this.handleKeyup}" list="${ifDefined(this.hasDataList ? 'datalist' : undefined)}" part="control"><slot name="postfix"></slot>${this.type === 'search' ? html`<odj-icon icon="search" class="search-icon"></odj-icon>` : ''} ${this.type === 'password' ? html`<odj-button @click="${() => this.showPassword = !this.showPassword}" icon="${passwordIcon}" class="odj-input-postfix"></odj-button>` : ''} ${this.copyable ? html`<odj-button @click="${this.handleCopy}" class="odj-input-postfix" icon="clipboard"></odj-button>` : ''}</div></div>`
    }

    render() {

        /* We show the status indicator under the following conditions:
         *   - The field has validation enabled (any validation attribute is set and it's not [standalone])
         *   - The field has been touched by the user or form validation has been triggered before
         */
        const shouldShowStatusIndicator = this.hasValidation && (this.isTouched || this.hasReportedValidity || this.hasAttribute('invalid'))
        const hasLabel = this.hasSlotController.test('label') || !!this.label
        const hasHelpText = (this.hasSlotController.test('help-text')) || !!this.helpText

        const controlWrapper = renderFormWrapper(
            {
                ref: this,
                required: this.required,
                invalid: this.invalid,
                helpText: this.helpText,
                label: this.label,
                hasLabel: hasLabel,
                hasHelpText: hasHelpText,
                id: this.inputId,
                labelId: this.labelId,
                validationPending: this.validationPending,
                standalone: this.standalone,
                errorText: this.errorText,
            },
            this.renderControl(shouldShowStatusIndicator)
        )

        return  html`${controlWrapper}<slot name="datalist"></slot>${this.copyable ? html`<odj-toast-container></odj-toast-container>` : ''}`
    }
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-input": OdjInput;
    }
}