/**
 * @event odj-show-content –
 */
customElements.define('odj-lazy-load', class extends HTMLTemplateElement {
	connectedCallback() {
		this.parentElement.addEventListener('odj-show-content', e => {
			this.load();
		});
	}
	
	load() {
		this.replaceWith(this.content.cloneNode(true));
	}
}, {extends: 'template'});