import { html, LitElement, TemplateResult } from "lit";
import { classMap } from "lit/directives/class-map.js";


export type FormWrapperOptions = {
    ref: LitElement;
    id: string;
    labelId: string;
    hasLabel: boolean;
    label: string | undefined;
    helpText: string | undefined;
    hasHelpText: boolean;
    required: boolean;
    invalid: boolean;
    standalone: boolean;
    errorText?: string;
    validationPending?: boolean;
};

export function renderFormWrapper(options: FormWrapperOptions, control: TemplateResult) {
    const label = html`<label id="${options.labelId}" for="${options.id}" class="${classMap({ required: options.required })}" part="label"><slot name="label">${options.label}</slot></label>`;

    const helpText = html`<div class="help-text"><slot name="help-text">${options.helpText}</slot></div>`;

    const errorText = options.validationPending ? html`<div class="validating-label">Validating...</div>` : options.errorText ? html`<div class="error-text">${options.errorText}</div>` : html``;

    const classes = {
        'form-field': true,
        'form-field--has-label': options.hasLabel,
        'form-field--has-help-text': options.hasHelpText,
        'form-field--standalone': options.standalone,
    };

    return html`<div class="${classMap(classes)}" @slotchange="${() => options.ref.requestUpdate()}">${label} ${control} ${errorText} ${helpText}</div>`;
}
