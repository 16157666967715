import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { componentStyles } from "./button-group.styles";


@customElement('odj-button-group')
export class OdjButtonGroup extends LitElement {
    
    static override styles = componentStyles

    render() {

        return html`<div class="btn-group"><slot></slot></div>`
    }

}

declare global {
    interface HTMLElementTagNameMap {
      "odj-button-group": OdjButtonGroup;
    }
}