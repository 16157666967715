import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';

/**
 * @cssproperty [--size=3rem] - The size of the spinner.
 */
@customElement('odj-spinner')
export class OdjSpinner extends LitElement {
	static styles = css`:host{display:inline-block}odj-icon{width:var(--size,3rem);height:var(--size,3rem);animation:rotate 1s infinite linear}@keyframes rotate{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}`
	render() {
		return html`<odj-icon icon="loading-circle" part="icon"></odj-icon>`
	}
}

declare global {
    interface HTMLElementTagNameMap {
      "odj-spinner": OdjSpinner;
    }
}